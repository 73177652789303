import {
    Box, Button, IconButton, Typography, useTheme, TextField, Container, InputAdornment, CircularProgress,
    Grid
} from "@mui/material";

import { tokens } from "../../theme";

// import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import { styled } from "@mui/material/styles";
// import { makeStyles } from "@mui/system";

import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

import SearchIcon from "@mui/icons-material/Search";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import { __fetching_data__ } from '../../components/api';
import { createChart, CrosshairMode, PriceScaleMode } from 'lightweight-charts';
import ReactDOM from 'react-dom';


var { log, error } = console;
var selectedTimezone = 'Asia/Bangkok';
var selectedChartSetting = null;
var isDragging = false;
var isChooseColor = false;
var selectLineWidth = 1;
var uniqueRandomColors = new Array;




const ChatbotPage = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const userDetail = useSelector((state) => state.userDetail);
    // console.log(userDetail)
    // if (userDetail.permissions && userDetail.permissions.permission && userDetail.permissions.permission.chatBot9Chanel && userDetail.permissions.permission.chatBot9Chanel.read) {
    //     console.log(1)
    // } else {
    //     console.log(2)
    // }

    const base_api_url =
        process.env.NODE_ENV === "production"
            ? window.location.protocol === "https:"
                ? process.env.REACT_APP_API_HTTPS_URL
                : process.env.REACT_APP_API_HTTP_URL
            : "/application";




    const [isMessageSent, setIsMessageSent] = useState(false);
    const [messages, setMessages] = useState([
        // { text: "Hello! I'm a chatbot.", isBot: true }
    ]);
    const [points, setPoints] = useState([])

    const [inputValue, setInputValue] = useState('');
    const messagesEndRef = useRef(null);

    // let [flow_series, setFlow_series] = useState()

    let [marker3, setMarker3] = useState()

    const scrollToBottom = () => {
        messagesEndRef.current && (messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight);
    };
    const scrollToBottomChatbot = () => {
        resChatBoxRef.current && (resChatBoxRef.current.scrollTop = resChatBoxRef.current.scrollHeight);

    }

    const resChatBoxRef = useRef(null);

    // useEffect(scrollToBottom, [messages]);
    // useEffect(() => {
    //     scrollToBottom
    // }, [messages]);

    useEffect(() => {
        console.log('start')
        scrollToBottomChatbot()
    }, [isMessageSent]);


    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const LoadingIndicator = () => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <CircularProgress sx={{ color: 'blue' }} />
        </div>
    );

    const responses = [];

    const handleSendMessage = async () => {
        console.log('start send message...')
        setIsMessageSent(true);
        if (!inputValue.trim()) return;
        setInputValue('');



        setMessages([...messages, { text: `You: ${inputValue}`, isBot: true }]);
        setTimeout(async () => {

            const chatbox = document.getElementById('chatbox');
            // ReactDOM.render(<LoadingIndicator />, chatbox);
            var value = {
                user_input: inputValue
            };
            //'<img src="/static/loading.gif" alt="Loading..." class="loading-indicator" width="100" height="100">';
            // const loadingIndicator = <CircularProgress />
            // const loadingIndicatorElement = document.createElement('div');
            // loadingIndicatorElement.innerHTML = loadingIndicator;
            // chatbox.appendChild(loadingIndicatorElement);
            const response = await __fetching_data__({ url: `${base_api_url}/chat_bot_report`, method: "POST", body: JSON.stringify(value) });
            console.log(response)
            if (response.status) {
                // chatbox.removeChild(loadingIndicatorElement);
                // ReactDOM.unmountComponentAtNode(chatbox);
                window.data = response.data;
                if (response.data.bot_response) {
                    responses.push(response.data);
                    // Display the new response and preserve interactions with previous responses
                    displayResponse(response.data, inputValue);
                } else if (response.data.plot_data) {
                    const responseContainer = displayPlot(response.data);
                    chatbox.appendChild(responseContainer);
                    // const responseContainer = displayPlot(response.data);
                    // chatbox.appendChild(responseContainer);
                } else if (response.data.index_data) {
                    setChartplot(response.data)
                } else if (response.data.marketsummary_data) {
                    marketSummaryPlot(response.data);
                } else if (response.data.petroleum_data) {
                    petroPlot(response.data);
                } else if (response.data.signal_response) {
                    signalResponse(response.data)
                }
                // if (resChatBoxRef.current) {
                //     resChatBoxRef.current.scrollTop = resChatBoxRef.current.scrollHeight;
                // }
            }

            //     .catch(error => {
            //         console.error('Fetch error:', error);
            //         // Provide user feedback about the error
            //         const errorElement = document.createElement('p');
            //         errorElement.classList.add('text-red-500');
            //         errorElement.textContent = `An error occurred. Please try again.${error}`;
            //         chatbox.appendChild(errorElement);

            //         // Remove loading indicator
            //         chatbox.removeChild(loadingIndicatorElement);
            //     });

            setIsMessageSent(false);
        }, 500);
    };


    const displayPlot = (response, sense = 0.05) => {
        const chatbox = document.getElementById('chatbox');
        const res_chat_box = document.getElementById('resChatBox');
        const plotData = response.plot_data;
        const symbol = plotData['symbol'];
        window.plotData = plotData

        const responseContainer = document.createElement('div');
        // responseContainer.style.width = '50%';
        // responseContainer.style.height = '600px';
        // responseContainer.style.maxHeight = '400px';
        // responseContainer.style.backgroundColor = 'lightblue';
        // responseContainer.setAttribute('class', 'grid grid-cols-3 grid-rows-4 gap-8 pt-11 flex flex-col mb-8');
        // responseContainer.setAttribute('class', 'MuiGrid-container');
        // responseContainer.setAttribute('style', 'grid-template-columns: repeat(4, 500px); grid-template-rows: repeat(2, 250px);');
        const containerWidth = 'auto';
        const containerHeight = 'auto';
        // responseContainer.setAttribute('id', 'sortable-grid');
        responseContainer.classList.add('response-container');
        responseContainer.style.display = 'grid';
        responseContainer.style.padding = '5px';
        responseContainer.style.gridTemplateColumns = `repeat(2, ${containerWidth})`;
        // responseContainer.style.gridTemplateColumns = `repeat(3, ${containerWidth})`;
        // responseContainer.style.gridTemplateColumns = `repeat(3,minmax(0,1fr)) `
        // responseContainer.style.gridTemplateRows = `repeat(3, ${containerHeight})`;
        // responseContainer.style.gridGap = '10px';

        const chartWidth = chatbox.offsetWidth / 2.1;
        const chartHeight = chatbox.offsetHeight / 3;
        const chartOption = { width: chartWidth, height: chartHeight, crosshair: { mode: 0, }, timeScale: { visible: true }, layout: { background: { type: 'solid', color: '#f5f5f5' } } }

        function chartAnnotation(chart, text) {
            chart.applyOptions({
                watermark: {
                    visible: true,
                    fontSize: 24,
                    horzAlign: 'left',
                    vertAlign: 'top',
                    color: 'rgb(0,0,0)',
                    text: text,
                },
                rightPriceScale: {
                    visible: true,
                },
                leftPriceScale: {
                    visible: true,
                    mode: PriceScaleMode.Normal,
                    autoScale: false,  // Disable automatic scaling
                    invertScale: false,
                    alignLabels: false,
                    minValue: -1,      // Set the minimum value
                    maxValue: 1,       // Set the maximum value
                },
            });

        }

        //Chart 1
        function generateChartLast() {
            const chartContainer = document.createElement('div');
            chartContainer.classList.add('chart-container');

            chartContainer.setAttribute('id', `chart-1`)
            const chart = createChart(chartContainer, chartOption);
            chartAnnotation(chart, 'Last')

            const headerText = document.createElement('div');
            headerText.textContent = 'Last';
            // chartContainer.appendChild(headerText);

            const addDivHeader = document.createElement('div')
            addDivHeader.setAttribute('class', 'divHeader');
            addDivHeader.appendChild(headerText)
            // addDivHeader.appendChild(toggleButton)
            // Append button to chart container
            chartContainer.appendChild(addDivHeader);

            const sumVal = plotData['price'].reduce((acc, dict) => {
                if ('value' in dict) {
                    return acc + dict['value'];
                }
                return acc;
            }, 0);

            let close_series = [];
            if (sumVal === 0 || plotData['price'].length <= 1) {
                chart.applyOptions({
                    watermark: {
                        visible: true,
                        fontSize: 14,
                        horzAlign: 'center',
                        vertAlign: 'center',
                        color: 'grey',
                        text: "Last:None",
                    },
                });
            } else {
                close_series = chart.addLineSeries({ title: '', color: 'green', lastValueVisible: true, priceLineVisible: false, priceScaleId: 'right', CrosshairMarkerVisible: false })
                chart.applyOptions({
                    watermark: {
                        visible: true,
                        fontSize: 48,
                        horzAlign: 'center',
                        vertAlign: 'center',
                        color: 'rgba(0, 0, 0, 0.1)',
                        text: `${inputValue}`,
                    },
                });
                const dataForSeries = generateData(plotData['price'], 'time', 'value');
                close_series.setData(dataForSeries);
            }

            return [chart, [close_series], chartContainer]
        }
        //Chart 2
        function generateChartFlow(sense = 0.05) {

            const chartContainer = document.createElement('div');
            chartContainer.classList.add('chart-container');
            chartContainer.classList.add('flow-chart');
            let flow_series = [];
            let markers = []
            // chartContainer.addEventListener('dblclick', ()=>{
            //     console.log('double click me...')
            // })

            const filterContainer = document.createElement('div');
            filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index:3;')
            const buttonContainer = document.createElement('div');
            buttonContainer.setAttribute('style', 'padding-left: 0%; position: absolute; z-index: 1;"')

            // Append the button container to the chart container
            filterContainer.appendChild(buttonContainer);
            chartContainer.appendChild(filterContainer);

            const chart = createChart(chartContainer, chartOption);
            chart.applyOptions({
                crosshair: {
                    mode: CrosshairMode.Normal,
                    // Horizontal crosshair line (showing Price in Label)
                    horzLine: {
                        labelVisible: false
                    },
                }
            })
            chart.priceScale('right').applyOptions({
                visible: false,
                textColor: '#F5F5F5',
            });
            chart.priceScale('left').applyOptions({
                visible: false,
                textColor: '#F5F5F5'

            });
            chartAnnotation(chart, 'Predicted Trend');

            const headerText = document.createElement('div');
            headerText.textContent = 'Predicted Trend';
            // chartContainer.appendChild(headerText);

            const addDivHeader = document.createElement('div')
            addDivHeader.setAttribute('class', 'divHeader');
            addDivHeader.appendChild(headerText)
            // addDivHeader.appendChild(toggleButton)
            // Append button to chart container
            chartContainer.appendChild(addDivHeader);

            const sumVal = plotData['flow_accum'].reduce((acc, dict) => {
                if ('value' in dict) {
                    return acc + dict['value'];
                }
                return acc;
            }, 0);

            if (sumVal === 0 || plotData['flow_accum'].length <= 1) {
                chart.applyOptions({
                    watermark: {
                        visible: true,
                        fontSize: 14,
                        horzAlign: 'center',
                        vertAlign: 'center',
                        color: 'grey',
                        text: "Flow:None",
                    },
                });
            } else {


                const series3_1 = chart.addLineSeries({ lineStyle: 0, lineWidth: 3, lastValueVisible: false, priceLineVisible: false, });
                // const flow_zigzag = chart.addLineSeries({title:'zigzag',color:'blue',lineStyle: 3, lineWidth: 2,lastValueVisible: false, priceLineVisible: false });
                chart.applyOptions({
                    watermark: {
                        visible: true,
                        fontSize: 48,
                        horzAlign: 'center',
                        vertAlign: 'center',
                        color: 'rgba(0, 0, 0, 0.1)',
                        text: `${inputValue}`,
                    },

                });
                const dataForSeries_1 = generateData(plotData['flow_accum'], 'time', 'value');
                // const dataZigzag = generateData(plotData['zigzag'][0], 'time', 'value');
                series3_1.setData(dataForSeries_1);

                // Filter flow_accum data based on zigzagIndices
                // const flow_value = plotData['flow_accum'].map(items => items.value)
                // const zigzagIndices = zigZag(flow_value,sense)
                // const filteredFlowAccum = zigzagIndices.map(index => plotData['flow_accum'][index]);

                // flow_zigzag.setData(dataZigzag);
                // flow_zigzag.setData(generateData(filteredFlowAccum, 'time', 'value'));

                series3_1.applyOptions({
                    priceFormat: {
                        type: 'volume',
                    },
                });
                // flow_zigzag.applyOptions({
                //     priceFormat: {
                //         type: 'volume',
                //     },
                //     visible : false
                // });
                function removeSeriesOutside() {
                    // You can now use flowChart here
                    if (chart && flow_zigzag) {
                        chart.removeSeries(flow_zigzag);
                    }
                }


                // window.removeSeriesOutside = removeSeriesOutside;
                // const hideZigZag = hideSeriesButton([flow_zigzag], 'zigzag', 'blue')
                // buttonContainer.appendChild(hideZigZag);
                console.log('marker', response)
                flow_series = [series3_1]
                let max_data = plotData['max_peaks']
                const flow_value = plotData['flow_accum'].map(items => items.value)
                
                console.log('points', points)
                var point_data = response['plot_data']['point_data']
                if(point_data){
                    for(var i =0;i<point_data.length; i++){
                        var point = point_data[i]
                        // var date = new Date(point[4] * 1000)
                        // console.log('date', point)
                        // var month = date.getMonth()+1
                        // if (month < 10){
                        //     month = '0'+month
                        // }
                        // var numDay = date.getDate()
                        // if(numDay < 10){
                        //     numDay = '0'+numDay
                        // }
                        let colorCode = ''
                        let charCode = ''
                        let barPosition = ''
                        if(point[8] == 'A'){
                          colorCode = '#008000'
                          charCode = 'U'
                          barPosition = 'aboveBar'
                        } else if(point[8] == 'V'){
                          colorCode = '#FF0000'
                          charCode = 'D'
                          barPosition = 'belowBar'
                        }
                        markers.push({
                            // 'time': date.getFullYear()+'-'+month+'-'+numDay,
                            'time': parseInt(point[11]),
                        // position: 'aboveBar',
                        position: barPosition,
                        color: colorCode,
                        shape: 'circle',
                        text: charCode
                        })
                    }
                }
                function compare( a, b ) {
                    if ( a.time < b.time ){
                      return -1;
                    }
                    if ( a.time > b.time ){
                      return 1;
                    }
                    return 0;
                }
                markers.sort(compare)
                series3_1.setMarkers(markers)

                chart.subscribeDblClick((param) => {
                    // console.log('plot', max_data)
                    let max_peaks = max_data['flow_accum']['max']
                    let min_peaks = max_data['flow_accum']['min']
                    let b = false
                    // let method = "add"
                    for(let i=0; i<markers.length; i++){
                      let obj = markers[i];
                      if(param['time'] == obj['time']){
                        b = true;
                        markers.splice(i, 1)
                        let valueSelect = ''
                        for (let [key, value] of param['seriesData']) {
                            valueSelect = value['value']
                        }
                        __fetching_data__({url: `${base_api_url}/chat_bot_save_point`, method: "POST", body: JSON.stringify(
                            {
                                // "user_name":"chaluemwut@gmail.com",
                                "chart_name": "predicted_trend",
                                "value": valueSelect,
                                // "position": valuePosition,
                                // "last_value": flow_value[flow_value.length-1],
                                "method": "del",
                                "timestamp": param['time']
                            }
                        ) }).then((res)=>{
                            console.log('res', res)
                        })
                      }
                    }
                    if(!b){
                        console.log('Hello')
                    //   console.log(param['value'], max_peaks.includes(param['value']))
                      let bMax = false
                      let bMin = false
                      let valueSelect = ''
                      let valuePosition = ''
                      let barPosition = ''
                      for (let [key, value] of param['seriesData']) {
                        if(max_peaks.includes(value['value'])){
                            valueSelect = value['value']
                            valuePosition = 'A'
                            bMax = true
                            barPosition = 'aboveBar'
                        } else if (min_peaks.includes(value['value'])){
                            valueSelect = value['value']
                            valuePosition = 'V'
                            bMin = true
                            barPosition = 'belowBar'
                        }
                      }
                    //   console.log('b', bMax, bMin)
                      let colorCode = ''
                      let charCode = ''
                      if(bMax){
                        colorCode = '#008000'
                        charCode = 'U'
                      } else if(bMin){
                        colorCode = '#FF0000'
                        charCode = 'D'
                      }
                      markers = [...markers, {
                        'time': param['time'],
                        position: barPosition,
                        color: colorCode,
                        shape: 'circle',
                        text: charCode
                      }]
                      
                      __fetching_data__({url: `${base_api_url}/chat_bot_save_point`, method: "POST", body: JSON.stringify(
                        {
                            // "user_name":"chaluemwut@gmail.com",
                            "chart_name": "predicted_trend",
                            "value": valueSelect,
                            "position": valuePosition,
                            "last_value": flow_value[flow_value.length-1],
                            "method": "add",
                            "symbol": plotData['symbol'],
                            "timestamp": param['time']
                        }
                      ) }).then((res)=>{
                        console.log('res', res)
                      })
                    }
                    console.log('time', markers)
                    function compare( a, b ) {
                        if ( a.time < b.time ){
                          return -1;
                        }
                        if ( a.time > b.time ){
                          return 1;
                        }
                        return 0;
                    }
                    markers.sort(compare)
                    series3_1.setMarkers(markers)
                });
            }
            return [chart, flow_series, chartContainer];
        }
        window.generateChartFlow = generateChartFlow;
        // chart 4
        function generateChartFlowNvdr(sense = 0.05) {
            const chartContainer = document.createElement('div');
            chartContainer.classList.add('chart-container');
            chartContainer.classList.add('nvdr-chart');
            let nvdr_series = [];

            const filterContainer = document.createElement('div');
            filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index:3;')
            const buttonContainer = document.createElement('div');
            buttonContainer.setAttribute('style', 'padding-left: 70%; position: absolute; z-index: 1;"')

            // Append the button container to the chart container
            filterContainer.appendChild(buttonContainer);
            chartContainer.appendChild(filterContainer);

            const chart = createChart(chartContainer, chartOption);
            chartAnnotation(chart, 'Nvdr');

            const sumVal = plotData['flow_nvdr'].reduce((acc, dict) => {
                if ('value' in dict) {
                    return acc + dict['value'];
                }
                return acc;
            }, 0);

            if (sumVal === 0 || plotData['flow_nvdr'].length <= 1) {
                chart.applyOptions({
                    watermark: {
                        visible: true,
                        fontSize: 14,
                        horzAlign: 'center',
                        vertAlign: 'center',
                        color: 'grey',
                        text: "Flow:None",
                    },
                });
            } else {


                const series_nvdr = chart.addBaselineSeries({
                    title: '',
                    baseValue: {
                        type: 'volume',
                        value: 0
                    },
                    topFillColor1: 'rgba(0, 0, 0, 0)',// or 'rgba(0, 0, 0, 0)' for transparent
                    topFillColor2: 'rgba(0, 0, 0, 0)',
                    bottomFillColor1: 'rgba(0, 0, 0, 0)',
                    bottomFillColor2: 'rgba(0, 0, 0, 0)',
                });

                chart.applyOptions({
                    watermark: {
                        visible: true,
                        fontSize: 14,
                    },
                });

                series_nvdr.applyOptions({
                    priceFormat: {
                        type: 'volume',
                    },
                });
                // const nvdr_zigzag = chart.addLineSeries({title:'zigzag',color:'blue',lineStyle: 3, lineWidth: 2,lastValueVisible: false,priceLineVisible: false });

                const dataForSeries_1 = generateData(plotData['flow_nvdr'], 'time', 'value');
                // const dataZigzag = generateData(plotData['zigzag'][0], 'time', 'value');
                series_nvdr.setData(dataForSeries_1);

                // Filter flow_accum data based on zigzagIndices
                // const flow_value = plotData['flow_nvdr'].map(items => items.value)
                // const zigzagIndices = zigZag(flow_value,sense)
                // const filteredFlowAccum = zigzagIndices.map(index => plotData['flow_nvdr'][index]);

                // flow_zigzag.setData(dataZigzag);
                // nvdr_zigzag.setData(generateData(filteredFlowAccum, 'time', 'value'));

                series_nvdr.applyOptions({
                    priceFormat: {
                        type: 'volume',
                    },
                });
                // nvdr_zigzag.applyOptions({
                //     priceFormat: {
                //         type: 'volume',
                //     },
                //     visible : false
                // });
                // function removeSeriesOutside() {
                //     // You can now use flowChart here
                //     if (chart && nvdr_zigzag) {
                //         chart.removeSeries(nvdr_zigzag);
                //     }
                // }


                // window.removeSeriesOutside = removeSeriesOutside;
                // const hideZigZag = hideSeriesButton([nvdr_zigzag], 'zigzag', 'blue')
                // buttonContainer.appendChild(hideZigZag);

                nvdr_series = [series_nvdr]
            }
            // chart.subscribeDblClick(() => {
            //     // Pass the custom parameter 'flow' to the modal
            //     openModal('nvdr');

            // });  
            return [chart, nvdr_series, chartContainer]
        }
        // function generateChartFlowNvdr(sense = 0.05){
        //     const chartContainer = document.createElement('div');
        //     chartContainer.classList.add('chart-container'); 

        //     const filterContainer = document.createElement('div');
        //     filterContainer.setAttribute('style','position: relative; overflow: visible; z-index:3;')
        //     const buttonContainer = document.createElement('div');
        //     buttonContainer.setAttribute('style', 'padding-left: 80%; position: absolute; z-index: 1;"')

        //         // Append the button container to the chart container
        //     filterContainer.appendChild(buttonContainer);
        //     chartContainer.appendChild(filterContainer);

        //     const chart = createChart(chartContainer, chartOption);
        //     chartAnnotation(chart, 'Net NVDR')
        //     let nvdr_series = [];
        //     const sumVal = plotData['flow_nvdr'].reduce((acc, dict) => {
        //         if ('value' in dict) {
        //             return acc + dict['value'];
        //         }
        //         return acc;
        //     }, 0);

        //     if (sumVal === 0 || plotData['flow_nvdr'].length <= 1){       
        //         chart.applyOptions({
        //             watermark: {
        //                 visible: true,
        //                 fontSize: 40,
        //                 horzAlign: 'center',
        //                 vertAlign: 'center',
        //                 color: 'grey',
        //                 text: "Flow NVDR:None", 
        //             },
        //         });     
        //     }else{
        //         nvdr_series = chart.addBaselineSeries({ 
        //             title:'Flow NVDR',
        //             baseValue: { 
        //                 type: 'volume',
        //                 value: 0 
        //             }, 
        //             topFillColor1 : 'rgba(0, 0, 0, 0)',// or 'rgba(0, 0, 0, 0)' for transparent
        //             topFillColor2 : 'rgba(0, 0, 0, 0)',
        //             bottomFillColor1 : 'rgba(0, 0, 0, 0)',
        //             bottomFillColor2 : 'rgba(0, 0, 0, 0)',
        //         });

        //         nvdr_series.applyOptions({
        //             priceFormat: {
        //                 type: 'volume',
        //             },
        //         });
        //         const dataForSeries = generateData(plotData['flow_nvdr'],'time','value');
        //         nvdr_series.setData(dataForSeries);

        //         nvdr_zigzag = chart.addLineSeries({title:'zigzag',color:'blue',lineStyle: 3, lineWidth: 2,lastValueVisible: false,priceLineVisible: false , visible:false});
        //         // const zigzagData = generateData(plotData['zigzag'][1], 'time', 'value')
        //         // nvdr_zigzag.setData(zigzagData);
        //         const nvdr_value = plotData['flow_nvdr'].map(items => items.value);
        //         const zizagIndices = zigZag(nvdr_value, sense);
        //         const filteredNvdr = zizagIndices.map(index => plotData['flow_nvdr'][index]);
        //         nvdr_zigzag.setData(filteredNvdr);

        //         const hideZigZag = hideSeriesButton([nvdr_zigzag], 'zigzag', 'blue')
        //         buttonContainer.appendChild(hideZigZag);



        //         // window.nvdr_zigzag = nvdr_zigzag;
        //     }
        //     return [chart, [nvdr_series], chartContainer]
        // }

        // chart 5

        //
        function generateChartFlowBidask() {
            const chartContainer = document.createElement('div');
            chartContainer.classList.add('chart-container');
            const chart = createChart(chartContainer, chartOption);
            chartAnnotation(chart, 'ความน่าสนใจ')
            let bidask_series = [];
            const sumVal = plotData['bidask'][0].reduce((acc, dict) => {
                if ('value' in dict) {
                    return acc + dict['value'];
                }
                return acc;
            }, 0);

            if (sumVal === 0 || plotData['bidask'][0].length <= 1) {
                chart.applyOptions({
                    watermark: {
                        visible: true,
                        fontSize: 14,
                        horzAlign: 'center',
                        vertAlign: 'center',
                        color: 'grey',
                        text: "BidAsk:None",
                    },
                });
            } else {
                const bidask_options = { axisLabelVisible: true, lastValueVisible: false, priceLineVisible: false, priceScaleId: 'right' };

                const series5_1 = chart.addLineSeries({ color: 'red', lineStyle: 0, lineWidth: 3, title: 'total bid', ...bidask_options });
                const series5_2 = chart.addLineSeries({ color: 'green', lineStyle: 0, lineWidth: 3, title: 'total offer', ...bidask_options });
                const series5_3 = chart.addLineSeries({ color: '#013220', lineStyle: 1, lineWidth: 2, ...bidask_options });
                const series5_4 = chart.addLineSeries({ color: '#8B0000', lineStyle: 1, lineWidth: 2, ...bidask_options });


                // Apply watermark options
                chart.applyOptions({
                    watermark: {
                        visible: true,
                        fontSize: 14,
                    },
                });

                // Array of series
                const series = [series5_1, series5_2, series5_3, series5_4];

                // Create a flag to track the visibility of titles
                let titlesVisible = false;

                // Create button to toggle visibility of specific series titles
                const toggleButton = document.createElement('button');
                // toggleButton.setAttribute('class', 'toggleButton');
                toggleButton.textContent = 'Hide/Show All';

                // Add event listener to toggle visibility of titles
                toggleButton.addEventListener('click', () => {
                    titlesVisible = !titlesVisible;
                    const buttonText = titlesVisible ? 'Hide/Show All' : 'Show/Hide All';
                    toggleButton.textContent = buttonText;

                    // Iterate through each series
                    series.forEach(s => {
                        if (s.options().title === 'total bid' || s.options().title === 'total offer') {
                            // Toggle visibility of 'Series 1' and 'Series 2' titles
                            s.applyOptions({
                                title: titlesVisible ? s.options().title : '',
                            });
                        } else if (s.options().title === '') {
                            // If the title is empty, set it to 'Series 1' or 'Series 2' based on its index
                            if (s === series5_1) {
                                s.applyOptions({
                                    title: titlesVisible ? 'total bid' : '',
                                });
                            } else if (s === series5_2) {
                                s.applyOptions({
                                    title: titlesVisible ? 'total offer' : '',
                                });
                            }
                        }
                    });
                });

                // Append the toggle button to the chart container
                // chartContainer.appendChild(toggleButton);
                const headerText = document.createElement('div');
                headerText.textContent = 'ความน่าสนใจ';
                // chartContainer.appendChild(headerText);

                const addDivHeader = document.createElement('div')
                addDivHeader.setAttribute('class', 'divHeader');
                addDivHeader.appendChild(headerText)
                addDivHeader.appendChild(toggleButton)
                // Append button to chart container
                chartContainer.appendChild(addDivHeader);

                bidask_series = [series5_1, series5_2, series5_3, series5_4];
                bidask_series.forEach((series, index) => {
                    const dataForSeries = generateData(plotData['bidask'][index], 'time', 'value');
                    series.setData(dataForSeries)

                    chart.applyOptions({
                        watermark: {
                            visible: true,
                            fontSize: 48,
                            color: 'rgba(0, 0, 0, 0.1)',
                            text: `${inputValue}`,  // Dynamic text based on inputValue
                            horzAlign: 'center',
                            vertAlign: 'center',
                        },
                    });
                    series.applyOptions({
                        priceFormat: {
                            type: 'volume',
                        }
                    })
                });

                series.forEach(s => {
                    if (s.options().title === 'total bid' || s.options().title === 'total offer') {
                        // Toggle visibility of 'Series 1' and 'Series 2' titles
                        s.applyOptions({
                            title: titlesVisible ? s.options().title : '',
                        });
                    } else if (s.options().title === '') {
                        // If the title is empty, set it to 'Series 1' or 'Series 2' based on its index
                        if (s === series5_1) {
                            s.applyOptions({
                                title: titlesVisible ? 'total bid' : '',
                            });
                        } else if (s === series5_2) {
                            s.applyOptions({
                                title: titlesVisible ? 'total offer' : '',
                            });
                        }
                    }
                });
            }

            return [chart, bidask_series, chartContainer]
        }
        // chart 6
        function generateChartTick() {
            const chartContainer = document.createElement('div');
            chartContainer.classList.add('chart-container');
            const chart = createChart(chartContainer, chartOption);
            chartAnnotation(chart, 'Value BS')

            const tickOptions = { axisLabelVisible: true, lastValueVisible: false, priceLineVisible: false, priceScaleId: 'right' };
            const series6_1 = chart.addLineSeries({ color: 'red', lineStyle: 0, lineWidth: 3, title: 'total value Sell', ...tickOptions });
            const series6_2 = chart.addLineSeries({ color: 'green', lineStyle: 0, lineWidth: 3, title: 'total value Buy', ...tickOptions });
            const series6_3 = chart.addLineSeries({ color: '#013220', lineStyle: 1, lineWidth: 2, title: '', ...tickOptions });
            const series6_4 = chart.addLineSeries({ color: '#8B0000', lineStyle: 1, lineWidth: 2, title: ' ', ...tickOptions });

            const series = [series6_1, series6_2, series6_3, series6_4];

            chart.applyOptions({
                watermark: {
                    visible: true,
                    fontSize: 48,
                    color: 'rgba(0, 0, 0, 0.1)',
                    text: `${inputValue}`,  // Dynamic text based on inputValue
                    horzAlign: 'center',
                    vertAlign: 'center',
                },
            });

            // Create a flag to track the visibility of titles
            let titlesVisible = false;

            // Create button to toggle visibility of specific series titles
            const toggleButton = document.createElement('button');
            // toggleButton.setAttribute('class', 'toggleButton');
            toggleButton.textContent = 'Show/Hide All';

            const headerText = document.createElement('div');
            headerText.textContent = 'Value BS';
            const addDivHeader = document.createElement('div')
            addDivHeader.setAttribute('class', 'divHeader');
            addDivHeader.appendChild(headerText)
            addDivHeader.appendChild(toggleButton)
            // Append button to chart container
            chartContainer.appendChild(addDivHeader);

            // Add event listener to toggle visibility of titles
            toggleButton.addEventListener('click', () => {
                titlesVisible = !titlesVisible;
                const buttonText = titlesVisible ? 'Hide/Show All' : 'Show/Hide All';
                toggleButton.textContent = buttonText;

                // Iterate through each series
                series.forEach(s => {
                    if (s.options().title === 'total value Sell' || s.options().title === 'total value Buy') {
                        // Toggle visibility of 'Series 1' and 'Series 2' titles
                        s.applyOptions({
                            title: titlesVisible ? s.options().title : '',
                        });
                    } else if (s.options().title === '') {
                        // If the title is empty, set it to 'Series 1' or 'Series 2' based on its index
                        if (s === series6_1) {
                            s.applyOptions({
                                title: titlesVisible ? 'total value Sell' : '',
                            });
                        } else if (s === series6_2) {
                            s.applyOptions({
                                title: titlesVisible ? 'total value Buy' : '',
                            });
                        }
                    }
                });
            });

            // Append the toggle button to the chart container
            // chartContainer.appendChild(toggleButton);

            series.forEach((series, index) => {
                const dataForSeries = generateData(plotData['tick'][index], 'time', 'value');
                series.setData(dataForSeries);

                series.applyOptions({
                    priceFormat: {
                        type: 'volume',
                    },
                });
            });

            series.forEach(s => {
                if (s.options().title === 'total value Sell' || s.options().title === 'total value Buy') {
                    // Toggle visibility of 'Series 1' and 'Series 2' titles
                    s.applyOptions({
                        title: titlesVisible ? s.options().title : '',
                    });
                } else if (s.options().title === '') {
                    // If the title is empty, set it to 'Series 1' or 'Series 2' based on its index
                    if (s === series6_1) {
                        s.applyOptions({
                            title: titlesVisible ? 'total value Sell' : '',
                        });
                    } else if (s === series6_2) {
                        s.applyOptions({
                            title: titlesVisible ? 'total value Buy' : '',
                        });
                    }
                }
            });

            return [chart, series, chartContainer]
        }
        // chart 7
        function generateChartTransac() {
            // Create chart container
            const chartContainer = document.createElement('div');
            chartContainer.classList.add('chart-container');

            // Create chart and annotate
            const chart = createChart(chartContainer, chartOption);
            chartAnnotation(chart, 'กระแส');

            // Series options
            const transacOptions = {
                axisLabelVisible: true,
                lastValueVisible: false,
                priceLineVisible: false,
                priceScaleId: 'right'
            };

            // Create line series with specified options
            const series1 = chart.addLineSeries({ color: 'green', lineStyle: 0, lineWidth: 3, title: 'total tick buy', ...transacOptions });
            const series2 = chart.addLineSeries({ color: 'red', lineStyle: 0, lineWidth: 3, title: 'total tick sell', ...transacOptions });
            const series3 = chart.addLineSeries({ color: '#8B0000', lineStyle: 1, lineWidth: 2, title: '', ...transacOptions });
            const series4 = chart.addLineSeries({ color: '#013220', lineStyle: 1, lineWidth: 2, title: '', ...transacOptions });



            // Apply watermark options

            chart.applyOptions({
                watermark: {
                    visible: true,
                    fontSize: 48,
                    color: 'rgba(0, 0, 0, 0.1)',
                    text: `${inputValue}`,  // Dynamic text based on inputValue
                    horzAlign: 'center',
                    vertAlign: 'center',
                },
            });
            // Array of series
            const series = [series1, series2, series3, series4];

            // Create a flag to track the visibility of titles
            let titlesVisible = false;

            // Create button to toggle visibility of specific series titles
            const toggleButton = document.createElement('button');
            // toggleButton.setAttribute('class', 'toggleButton');
            toggleButton.textContent = 'Show/Hide All';

            // Add event listener to toggle visibility of titles
            toggleButton.addEventListener('click', () => {
                titlesVisible = !titlesVisible;
                const buttonText = titlesVisible ? 'Hide/Show All' : 'Show/Hide All';
                toggleButton.textContent = buttonText;

                // Iterate through each series
                series.forEach(s => {
                    if (s.options().title === 'total tick buy' || s.options().title === 'total tick sell') {
                        // Toggle visibility of 'Series 1' and 'Series 2' titles
                        s.applyOptions({
                            title: titlesVisible ? s.options().title : '',
                        });
                    } else if (s.options().title === '') {
                        // If the title is empty, set it to 'Series 1' or 'Series 2' based on its index
                        if (s === series1) {
                            s.applyOptions({
                                title: titlesVisible ? 'total tick buy' : '',
                            });
                        } else if (s === series2) {
                            s.applyOptions({
                                title: titlesVisible ? 'total tick sell' : '',
                            });
                        }
                    }
                });
            });

            // Append the toggle button to the chart container
            // chartContainer.appendChild(toggleButton);

            const headerText = document.createElement('div');
            headerText.textContent = 'กระแส';
            // chartContainer.appendChild(headerText);

            const addDivHeader = document.createElement('div')
            addDivHeader.setAttribute('class', 'divHeader');
            addDivHeader.appendChild(headerText)
            addDivHeader.appendChild(toggleButton)
            // Append button to chart container
            chartContainer.appendChild(addDivHeader);

            // Set data for each series
            series.forEach((s, index) => {
                const dataForSeries = generateData(plotData['transac'][index], 'time', 'value');
                s.setData(dataForSeries);

                s.applyOptions({
                    priceFormat: {
                        type: 'volume',
                    },
                });
            });

            series.forEach(s => {
                if (s.options().title === 'total tick buy' || s.options().title === 'total tick sell') {
                    // Toggle visibility of 'Series 1' and 'Series 2' titles
                    s.applyOptions({
                        title: titlesVisible ? s.options().title : '',
                    });
                } else if (s.options().title === '') {
                    // If the title is empty, set it to 'Series 1' or 'Series 2' based on its index
                    if (s === series1) {
                        s.applyOptions({
                            title: titlesVisible ? 'total tick buy' : '',
                        });
                    } else if (s === series2) {
                        s.applyOptions({
                            title: titlesVisible ? 'total tick sell' : '',
                        });
                    }
                }
            });

            return [chart, series, chartContainer];
        }


        // function generateChartReport(){
        //         const chartContainer = document.createElement('div');
        //         chartContainer.classList.add('chart-container'); 

        //         const chart = createChart(chartContainer, chartOption);
        //         chartAnnotation(chart, 'Report');

        //         let report_series = [];

        //         if (plotData['report'].length < 1) {
        //             chart.applyOptions({
        //                 watermark: {
        //                     visible: true,
        //                     fontSize: 40,
        //                     horzAlign: 'center',
        //                     vertAlign: 'center',
        //                     color: 'grey',
        //                     text: "Report:None", 
        //                 },
        //             });

        //         } else {
        //             const report = plotData['report'];
        //             const name_59 = plotData['name_59'].slice(1);
        //             const name_246 = plotData['name_246'].slice(1);
        //             const report_name = plotData['name_report'].slice(1);
        //             const length_report = Math.min(report.length,  report_name.length);

        //             for (let i = 0; i < length_report; i++){
        //                 const data = report[i];
        //                 const seriesData =  generateData(data, 'time', 'value')
        //                 const name = report_name[i];
        //                 const color = getRandomColor();

        //                 const reportSeries  = chart.addLineSeries({ title: `${name}`, color: color })
        //                 reportSeries.applyOptions({
        //                     priceFormat:{
        //                         type:'volume',
        //                     },
        //                 });
        //                 reportSeries.setData(seriesData);
        //                 report_series.push(reportSeries)
        //             }
        //         }

        //         return [chart, report_series, chartContainer];

        // }
        function generateChartPeak() {
            const chartContainer = document.createElement('div');
            chartContainer.classList.add('chart-container');
            // const filterContainer = document.createElement('div');
            // filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index: 3;');
            // chartContainer.appendChild(filterContainer);

            const chart = createChart(chartContainer, chartOption);
            chart.applyOptions({
                crosshair: {
                    mode: CrosshairMode.Normal,
                    // Horizontal crosshair line (showing Price in Label)
                    horzLine: {
                        labelVisible: false
                    },
                },

            })
            chart.priceScale('right').applyOptions({
                visible: false,
                textColor: '#F5F5F5'

            });
            chart.priceScale('left').applyOptions({
                visible: false,
                textColor: '#F5F5F5'

            });
            chartAnnotation(chart, 'Peak');

            const headerText = document.createElement('div');
            headerText.textContent = 'Peak';
            // chartContainer.appendChild(headerText);

            const addDivHeader = document.createElement('div')
            addDivHeader.setAttribute('class', 'divHeader');
            addDivHeader.appendChild(headerText)
            // addDivHeader.appendChild(toggleButton)
            // Append button to chart container
            chartContainer.appendChild(addDivHeader);

            // let swap_series = [];
            let series = [];
            // let sma_series = [];

            // chart.subscribeDblClick((param)=>{
            //     console.log('chart...')
            // })

            if (!plotData['swap'] | plotData['swap'].length === 0) {
                chart.applyOptions({
                    watermark: {
                        visible: true,
                        fontSize: 14,
                        horzAlign: 'center',
                        vertAlign: 'center',
                        color: 'grey',
                        text: "Swap:None",
                    },
                });

            } else {


                // const series_1 = chart.addLineSeries({ color: 'red', lineStyle: 0, lineWidth: 2, title: 'bid', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'right' });
                // const series_2 = chart.addLineSeries({ color: 'green', lineStyle: 0, lineWidth: 2, title: 'ask', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'right' });
                const series_3 = chart.addLineSeries({ color: 'black', lineStyle: 0, lineWidth: 2, title: 'sum', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'right' });
                const dataForSeries = generateData(plotData['swap'], 'time', 'value');
                chart.applyOptions({
                    watermark: {
                        visible: true,
                        fontSize: 48,
                        horzAlign: 'center',
                        vertAlign: 'center',
                        color: 'rgba(0, 0, 0, 0.1)',
                        text: `${inputValue}`,
                    },
                });
                series_3.setData(dataForSeries);
                series.push(series_3);


                let max_data = plotData['max_peaks']
                let markers = []
                chart.subscribeDblClick((param) => {
                    // console.log('plot', max_data)
                    let max_peaks = max_data['price']['max']
                    let min_peaks = max_data['price']['min']
                    let b = false;
                    for(let i=0; i<markers.length; i++){
                      let obj = markers[i];
                      if(param['time'] == obj['time']){
                        b = true;
                        markers.splice(i, 1); 
                      }
                    }
                    if(!b){
                        console.log('Hello')
                    //   console.log(param['value'], max_peaks.includes(param['value']))
                      let bMax = false
                      let bMin = false
                      for (let [key, value] of param['seriesData']) {
                        if(max_peaks.includes(value['value'])){
                            bMax = true
                        } else if (min_peaks.includes(value['value'])){
                            bMin = true
                        }
                      }
                    //   console.log('b', bMax, bMin)
                      let colorCode = ''
                      let charCode = ''
                      if(bMax){
                        colorCode = '#008000'
                        charCode = 'U'
                      } else if(bMin){
                        colorCode = '#FF0000'
                        charCode = 'D'
                      }
                      markers = [...markers, {
                        'time': param['time'],
                        position: 'aboveBar',
                        color: colorCode,
                        shape: 'circle',
                        text: charCode
                      }]
                    }
                    function compare( a, b ) {
                        if ( a.time < b.time ){
                          return -1;
                        }
                        if ( a.time > b.time ){
                          return 1;
                        }
                        return 0;
                    }
                    markers.sort(compare)
                    series_3.setMarkers(markers)
                });
                // plotData['swap_ind']['sma_all_sig'].forEach(sma => {
                //     const data_series = generateData(sma,'time','value');
                //     const sma_seri = chart.addLineSeries({color:'orange',lineStyle: 2, lineWidth: 2,lastValueVisible: false,priceLineVisible: false })
                //     sma_seri.setData(data_series);
                //     sma_series.push(sma_seri);
                // })

                // const buttonContainer = document.createElement('div');
                // buttonContainer.setAttribute('style', 'padding-left: 20%; position: absolute; z-index: 1;');


                // const bband = hideSeriesButton(band_series, 'bband', 'purple')
                // const sma = hideSeriesButton(sma_series, 'sma', 'orange')

                // buttonContainer.appendChild(bband);
                // buttonContainer.appendChild(sma);

                // filterContainer.appendChild(buttonContainer);
            }



            return [chart, series, chartContainer];
        }

        function generateChartShort() {
            const chartContainer = document.createElement('div');
            chartContainer.classList.add('chart-container');
            const chart = createChart(chartContainer, { ...chartOption, leftPriceScale: { visible: true, }, });
            chartAnnotation(chart, '%short');

            console.log(plotData)
            const sumVal = plotData['short'].reduce((acc, dict) => {
                return 'value' in dict ? acc + dict['value'] : acc;
            }, 0);

            let short_series = [];
            let short_series1 = [];

            // const series5 = chart.addLineSeries({
            //     priceScaleId: 'left',
            //     // upColor: '#26a69a', downColor: '#ef5350', borderVisible: false,
            //     // wickUpColor: '#26a69a', wickDownColor: '#ef5350',
            //     color: 'blude', lineStyle: 0, lineWidth: 3, title: '1111', ...transacOptions
            // });

            if (sumVal === 0 || plotData['short'].length <= 1) {
                chart.applyOptions({
                    watermark: {
                        visible: true,
                        fontSize: 14,
                        horzAlign: 'center',
                        vertAlign: 'center',
                        color: 'grey',
                        text: "%Short: None",
                    },
                });
            } else {
                const dataForSeries = generateData(plotData['short'], 'time', 'value');
                short_series = chart.addLineSeries({ color: 'red', lastValueVisible: true, title: '% daily short', priceLineVisible: false });
                short_series.setData(dataForSeries);

                const dataForSeries1 = generateData(plotData['outstanding_short'], 'time', 'value');
                short_series1 = chart.addLineSeries({ color: 'green', lastValueVisible: true, title: 'outstandingshort', priceLineVisible: false, priceScaleId: 'left', });
                short_series1.setData(dataForSeries1);
                short_series1.applyOptions({
                    priceFormat: {
                        type: 'volume',
                    }
                })

                chart.applyOptions({
                    watermark: {
                        visible: true,
                        fontSize: 48,
                        color: 'rgba(0, 0, 0, 0.1)',
                        text: `${inputValue}`,  // Dynamic text based on inputValue
                        horzAlign: 'center',
                        vertAlign: 'center',
                    },
                });
            }
            const series = [short_series1, short_series]
            let titlesVisible = false;

            // Create button to toggle visibility of specific series titles
            const toggleButton = document.createElement('button');
            // toggleButton.setAttribute('class', 'toggleButton');
            toggleButton.textContent = 'Show/Hide All';

            const headerText = document.createElement('div');
            headerText.textContent = '%short';
            // chartContainer.appendChild(headerText);

            const addDivHeader = document.createElement('div')
            addDivHeader.setAttribute('class', 'divHeader');
            addDivHeader.appendChild(headerText)
            addDivHeader.appendChild(toggleButton)
            // Append button to chart container
            chartContainer.appendChild(addDivHeader);

            // Add event listener to toggle visibility of titles
            toggleButton.addEventListener('click', () => {
                titlesVisible = !titlesVisible;
                const buttonText = titlesVisible ? 'Hide/Show All' : 'Show/Hide All';
                toggleButton.textContent = buttonText;

                // Iterate through each series
                series.forEach(s => {
                    if (s.options().title === 'outstandingshort' || s.options().title === '% daily short') {
                        // Toggle visibility of 'Series 1' and 'Series 2' titles
                        s.applyOptions({
                            title: titlesVisible ? s.options().title : '',
                        });
                    } else if (s.options().title === '') {
                        // If the title is empty, set it to 'Series 1' or 'Series 2' based on its index
                        if (s === short_series) {
                            s.applyOptions({
                                
                                title: titlesVisible ? '% daily short' : '',
                            });
                        } else if (s === short_series1) {
                            s.applyOptions({
                                title: titlesVisible ? 'outstandingshort' : '',
                            });
                        }
                    }
                });
            });

            series.forEach(s => {
                if (s.length == 0) {
                    return;
                }
                if (s.options().title === 'outstandingshort' || s.options().title === '% daily short') {
                    // Toggle visibility of 'Series 1' and 'Series 2' titles
                    s.applyOptions({
                        title: titlesVisible ? s.options().title : '',
                    });
                } else if (s.options().title === '') {
                    // If the title is empty, set it to 'Series 1' or 'Series 2' based on its index
                    if (s === short_series) {
                        s.applyOptions({
                            title: titlesVisible ? 'outstandingshort' : '',
                        });
                    } else if (s === short_series1) {
                        s.applyOptions({
                            title: titlesVisible ? '% daily short' : '',
                        });
                    }
                }
            });

            // Append the toggle button to the chart container
            // chartContainer.appendChild(toggleButton);
            // series.forEach(s => {
            //     if (s.options().title === 'outstandingshort' || s.options().title === '% daily short') {
            //         // Toggle visibility of 'Series 1' and 'Series 2' titles
            //         s.applyOptions({
            //             title: titlesVisible ? s.options().title : '',
            //         });
            //     } else if (s.options().title === '') {
            //         // If the title is empty, set it to 'Series 1' or 'Series 2' based on its index
            //         if (s === short_series) {
            //             s.applyOptions({
            //                 title: titlesVisible ? 'outstandingshort' : '',
            //             });
            //         } else if (s === short_series1) {
            //             s.applyOptions({
            //                 title: titlesVisible ? '% daily short' : '',
            //             });
            //         }
            //     }
            // });
            return [chart, [short_series, short_series1], chartContainer];
        }

        // function hideSeriesButton(seriesList, text, color) {
        //     function createColorIndicator(color, isVisible) {
        //         const colorIndicator = document.createElement('span');
        //         colorIndicator.style.display = 'inline-block';
        //         colorIndicator.style.width = '10px';
        //         colorIndicator.style.height = '10px';
        //         colorIndicator.style.backgroundColor = isVisible ? color : 'transparent';
        //         colorIndicator.style.marginRight = '5px';
        //         return colorIndicator;
        //     }

        //     const button = document.createElement('button');
        //     button.setAttribute('class', 'btn');
        //     button.textContent = text;
        //     button.setAttribute('style', 'z-index: 2;');
        //     button.appendChild(createColorIndicator(color, seriesList[0].options().visible));

        //     button.addEventListener('click', () => {
        //         // Toggle visibility for each series in the list
        //         seriesList.forEach(series => {
        //             series.applyOptions({
        //                 visible: !series.options().visible,
        //             });
        //         });
        //     });

        //     return button;
        // }
        function hideSeriesButton(seriesList, text, color) {
            function createColorIndicator(color, isVisible) {
                const colorIndicator = document.createElement('span');
                colorIndicator.style.display = 'inline-block';
                colorIndicator.style.width = '10px';
                colorIndicator.style.height = '10px';
                colorIndicator.style.backgroundColor = isVisible ? color : 'transparent';
                colorIndicator.style.marginRight = '5px';
                return colorIndicator;
            }

            const button = document.createElement('button');
            button.setAttribute('class', 'btn');
            button.textContent = text;
            button.setAttribute('style', 'z-index: 2;');
            button.appendChild(createColorIndicator(color, seriesList[0].options().visible));

            button.addEventListener('click', () => {
                // Toggle visibility for each series in the list
                const isVisible = !seriesList[0].options().visible;
                seriesList.forEach(series => {
                    series.applyOptions({
                        visible: isVisible,
                    });
                });
                // Update color indicator
                const colorIndicator = button.querySelector('span');
                colorIndicator.style.backgroundColor = isVisible ? color : 'transparent';
            });

            return button;
        }
        function generateChart59() {
            const chartContainer = document.createElement('div');
            chartContainer.classList.add('chart-container');
            const chart = createChart(chartContainer, chartOption);
            chartAnnotation(chart, 'ผู้บริหาร');
            const seriesArray = []; // Array to store series arrays
            const seriesTitles = []; // Array to store series titles


            if (plotData['all_59'].length === 0) {
                chart.applyOptions({
                    watermark: {
                        visible: true,
                        fontSize: 14,
                        horzAlign: 'center',
                        vertAlign: 'center',
                        color: 'grey',
                        text: "59-2:None",
                    },
                });
            } else {
                const data_59 = plotData['all_59'];
                const name_59 = plotData['name_59'].slice(1);
                for (let i = 0; i < name_59.length; i++) {
                    const data = data_59[i];
                    const name = name_59[i];
                    const color = getRandomColor();
                    const seriesData = generateData(data, 'time', 'value');

                    // Version 1: Line series without title
                    const series_59_without_title = chart.addLineSeries({ color: color });
                    series_59_without_title.applyOptions({
                        priceFormat: {
                            type: 'volume',
                        },
                        visible: false, // Initially hide this series
                    });
                    series_59_without_title.setData(seriesData);

                    // Version 2: Line series with title
                    const series_59_with_title = chart.addLineSeries({ title: `${name}`, color: color });
                    series_59_with_title.applyOptions({
                        priceFormat: {
                            type: 'volume',
                        },
                    });
                    series_59_with_title.setData(seriesData);

                    // Store both versions in an array
                    seriesArray.push([series_59_without_title, series_59_with_title]);
                    seriesTitles.push(name);
                }

                // Show the series with title initially
                seriesArray.forEach(seriesPair => {
                    seriesPair[1].applyOptions({ visible: true });
                });

                // Top left Symbol font size in chart
                chart.applyOptions({
                    watermark: {
                        visible: true,
                        fontSize: 48,
                        color: 'rgba(0, 0, 0, 0.1)',
                        text: `${inputValue}`,  // Dynamic text based on inputValue
                        horzAlign: 'center',
                        vertAlign: 'center',
                    },
                });

                // Function to toggle visibility of all series
                let titleVisibilityState = false; // Initial state of title visibility

                function toggleAllSeriesTitleVisibility() {
                    seriesArray.forEach(seriesPair => {
                        const [seriesWithoutTitle, seriesWithTitle] = seriesPair;
                        const currentTitleVisibility = seriesWithTitle.options().title;

                        // Toggle title visibility
                        if (titleVisibilityState) {
                            seriesWithoutTitle.applyOptions({ visible: true });
                            seriesWithTitle.applyOptions({ visible: false });
                        } else {
                            seriesWithoutTitle.applyOptions({ visible: false });
                            seriesWithTitle.applyOptions({ visible: true });
                        }
                    });

                    // Update title visibility state
                    titleVisibilityState = !titleVisibilityState;
                }


                // Button to control visibility of all series
                const toggleButton = document.createElement('button');
                // toggleButton.setAttribute('class', 'toggleButton'); // Add id attribute
                toggleButton.textContent = 'Hide/Show All';
                toggleButton.addEventListener('click', toggleAllSeriesTitleVisibility);
                // toggleAllSeriesTitleVisibility()
                // chartContainer.appendChild(toggleButton);

                const headerText = document.createElement('div');
                headerText.textContent = 'ผู้บริหาร';
                // chartContainer.appendChild(headerText);

                const addDivHeader = document.createElement('div')
                addDivHeader.setAttribute('class', 'divHeader');
                addDivHeader.appendChild(headerText)
                addDivHeader.appendChild(toggleButton)
                // Append button to chart container
                chartContainer.appendChild(addDivHeader);
                // toggleAllSeriesTitleVisibility()

                // Update title visibility state
                // titleVisibilityState = !titleVisibilityState;
                // addHeader('ผู้บริหาร', chartContainer);

                // Click event handler for individual series
                chart.subscribeClick(mouseParams => {
                    console.log('Click event triggered');
                    console.log('mouseParams:', mouseParams);

                    if (!mouseParams || !mouseParams.point || !mouseParams.hoveredSeries) {
                        console.log('No point or hovered series data found');
                        return;
                    }

                    const clickedSeries = mouseParams.hoveredSeries;
                    const seriesIndex = seriesArray.findIndex(s => s.includes(clickedSeries));

                    if (seriesIndex === -1) {
                        console.log('Clicked series not found');
                        return;
                    }

                    // Toggle visibility between the two versions of the series
                    const [seriesWithoutTitle, seriesWithTitle] = seriesArray[seriesIndex];

                    // Check if series with title is currently visible
                    if (seriesWithTitle.options().visible) {
                        seriesWithoutTitle.applyOptions({ visible: true });
                        seriesWithTitle.applyOptions({ visible: false });
                    } else {
                        seriesWithoutTitle.applyOptions({ visible: false });
                        seriesWithTitle.applyOptions({ visible: true });
                    }

                    console.log('Toggled visibility for series:', seriesTitles[seriesIndex]);
                });

            }

            seriesArray.forEach(seriesPair => {
                const [seriesWithoutTitle, seriesWithTitle] = seriesPair;
                const currentTitleVisibility = seriesWithTitle.options().title;
                seriesWithoutTitle.applyOptions({ visible: true });
                seriesWithTitle.applyOptions({ visible: false });
            });

            return [chart, seriesArray[0], chartContainer];
        }

        function addHeader(title, chartContainer) {
            const headerText = document.createElement('div');
            headerText.setAttribute('class', 'header-text'); // Add id attribute
            // headerText.textContent = 'ผู้บริหาร';
            headerText.textContent = title;
            chartContainer.appendChild(headerText);
        }

        //246-2
        function generateChart246() {
            const chartContainer = document.createElement('div');
            chartContainer.classList.add('chart-container');
            const chart = createChart(chartContainer, chartOption);
            chartAnnotation(chart, 'Shareholder > 5%');
            const seriesArray = []; // Array to store series arrays
            const seriesTitles = []; // Array to store series titles

            if (plotData['all_246'].length === 0) {
                chart.applyOptions({
                    watermark: {
                        visible: true,
                        fontSize: 14,
                        horzAlign: 'center',
                        vertAlign: 'center',
                        color: 'grey',
                        text: "246-2:None",
                    },
                });
            } else {
                const data_59 = plotData['all_246'];
                const name_59 = plotData['name_246'].slice(1);
                for (let i = 0; i < name_59.length; i++) {
                    const data = data_59[i];
                    const name = name_59[i];
                    const color = getRandomColor();
                    const seriesData = generateData(data, 'time', 'value');

                    // Version 1: Line series without title
                    const series_59_without_title = chart.addLineSeries({ color: color });
                    series_59_without_title.applyOptions({
                        priceFormat: {
                            type: 'volume',
                        },
                        visible: false, // Initially hide this series
                    });
                    series_59_without_title.setData(seriesData);

                    // Version 2: Line series with title
                    const series_59_with_title = chart.addLineSeries({ title: `${name}`, color: color });
                    series_59_with_title.applyOptions({
                        priceFormat: {
                            type: 'volume',
                        },
                    });
                    series_59_with_title.setData(seriesData);

                    // Store both versions in an array
                    seriesArray.push([series_59_without_title, series_59_with_title]);
                    seriesTitles.push(name);
                }

                // Show the series with title initially
                seriesArray.forEach(seriesPair => {
                    seriesPair[1].applyOptions({ visible: true });
                });

                // Top left Symbol font size in chart
                chart.applyOptions({
                    watermark: {
                        visible: true,
                        fontSize: 48,
                        color: 'rgba(0, 0, 0, 0.1)',
                        text: `${inputValue}`,  // Dynamic text based on inputValue
                        horzAlign: 'center',
                        vertAlign: 'center',
                    },
                });

                // Function to toggle visibility of all series
                let titleVisibilityState = false; // Initial state of title visibility

                function toggleAllSeriesTitleVisibility() {
                    seriesArray.forEach(seriesPair => {
                        const [seriesWithoutTitle, seriesWithTitle] = seriesPair;
                        const currentTitleVisibility = seriesWithTitle.options().title;

                        // Toggle title visibility
                        if (titleVisibilityState) {
                            seriesWithoutTitle.applyOptions({ visible: true });
                            seriesWithTitle.applyOptions({ visible: false });
                        } else {
                            seriesWithoutTitle.applyOptions({ visible: false });
                            seriesWithTitle.applyOptions({ visible: true });
                        }
                    });

                    // Update title visibility state
                    titleVisibilityState = !titleVisibilityState;
                }


                // Button to control visibility of all series
                const toggleButton = document.createElement('button');
                // toggleButton.setAttribute('class', 'toggleButton246'); // Add id attribute

                toggleButton.textContent = 'Hide/Show All';
                toggleButton.addEventListener('click', toggleAllSeriesTitleVisibility);

                // Append button to chart container
                // chartContainer.appendChild(toggleButton);

                const headerText = document.createElement('div');
                headerText.textContent = 'Shareholder > 5%';

                const addDivHeader = document.createElement('div')
                addDivHeader.setAttribute('class', 'divHeader');
                addDivHeader.appendChild(headerText)
                addDivHeader.appendChild(toggleButton)
                // Append button to chart container
                chartContainer.appendChild(addDivHeader);

                // addHeader('Shareholder > 5%', chartContainer)

                // Click event handler for individual series
                chart.subscribeClick(mouseParams => {
                    console.log('Click event triggered');
                    console.log('mouseParams:', mouseParams);

                    if (!mouseParams || !mouseParams.point || !mouseParams.hoveredSeries) {
                        console.log('No point or hovered series data found');
                        return;
                    }

                    const clickedSeries = mouseParams.hoveredSeries;
                    const seriesIndex = seriesArray.findIndex(s => s.includes(clickedSeries));

                    if (seriesIndex === -1) {
                        console.log('Clicked series not found');
                        return;
                    }

                    // Toggle visibility between the two versions of the series
                    const [seriesWithoutTitle, seriesWithTitle] = seriesArray[seriesIndex];

                    // Check if series with title is currently visible
                    if (seriesWithTitle.options().visible) {
                        seriesWithoutTitle.applyOptions({ visible: true });
                        seriesWithTitle.applyOptions({ visible: false });
                    } else {
                        seriesWithoutTitle.applyOptions({ visible: false });
                        seriesWithTitle.applyOptions({ visible: true });
                    }

                    console.log('Toggled visibility for series:', seriesTitles[seriesIndex]);
                });

            }
            seriesArray.forEach(seriesPair => {
                const [seriesWithoutTitle, seriesWithTitle] = seriesPair;
                const currentTitleVisibility = seriesWithTitle.options().title;
                seriesWithoutTitle.applyOptions({ visible: true });
                seriesWithTitle.applyOptions({ visible: false });
            });
            return [chart, seriesArray[0], chartContainer];
        }

        function generateChart2460() {
            const chartContainer = document.createElement('div');
            chartContainer.classList.add('chart-container');
            const chart = createChart(chartContainer, chartOption);
            chartAnnotation(chart, 'Shareholder > 5%');
            addHeader('Shareholder > 5%', chartContainer)
            const series = [];
            if (plotData['all_246'].length === 0) {
                chart.applyOptions({
                    watermark: {
                        visible: true,
                        fontSize: 14,
                        horzAlign: 'center',
                        vertAlign: 'center',
                        color: 'grey',
                        text: "246-2:None",
                    },
                });
            } else {
                const data_246 = plotData['all_246'];
                const name_246 = plotData['name_246'].slice(1);
                for (let i = 0; i < name_246.length; i++) {
                    const data = data_246[i];
                    const name = name_246[i];
                    const color = getRandomColor();
                    const seriesData = generateData(data, 'time', 'value')
                    const series_246 = chart.addLineSeries({ title: `${name}`, color: color })
                    // const series_246 = chart.addLineSeries({ title: ``, color: color })
                    series_246.applyOptions({
                        priceFormat: {
                            type: 'volume',
                        },
                    });
                    chart.applyOptions({
                        watermark: {
                            visible: true,
                            fontSize: 48,
                            color: 'rgba(0, 0, 0, 0.1)',
                            text: `${inputValue}`,  // Dynamic text based on inputValue
                            horzAlign: 'center',
                            vertAlign: 'center',
                        },
                    });
                    series_246.setData(seriesData);
                    series.push(series_246);
                }

            }
            return [chart, series, chartContainer]
        }


        // const [chart1, series1, chartContainer1] = generateChartLast();
        // const [chart2, series2, chartContainer2] = generateChartFlow(sense);
        // // const [chart3, series3, chartContainer3] = generateChartSwap();
        // const [chart4, series4, chartContainer4] = generateChartFlowNvdr();
        // const [chart5, series5, chartContainer5] = generateChartFlowBidask();
        // const [chart6, series6, chartContainer6] = generateChartTick();
        // const [chart7, series7, chartContainer7] = generateChartTransac();
        // const [chart8, series8, chartContainer8] = generateChart59();
        // const [chart9, series9, chartContainer9] = generateChart246();
        // const [chart10, series10, chartContainer10] = generateChartShort();
        // const [chart11, series11, chartContainer11] = generateChartPeak();

        const [chart1, series1, chartContainer1] = generateChartLast();
        const [chart2, series2, chartContainer2] = generateChartPeak();
        // const [chart3, series3, chartContainer3] = generateChartSwap();
        const [chart4, series4, chartContainer4] = generateChartFlow(sense);

        const [chart5, series5, chartContainer5] = generateChart59();
        const [chart6, series6, chartContainer6] = generateChart246();
        // const [chart7, series7, chartContainer7] = generateChartFlowNvdr();

        const [chart8, series8, chartContainer8] = generateChartShort();
        // const [chart9, series9, chartContainer9] = generateChartFlowBidask();
        // const [chart10, series10, chartContainer10] = generateChartTick();

        // const [chart11, series11, chartContainer11] = generateChartTransac();


        chart2.subscribeDblClick(() => {
            // Pass the custom parameter 'flow' to the modal
            // openModal('flow');

        });
        chart5.subscribeDblClick(() => {
            // Pass the custom parameter 'flow' to the modal
            // openModal('nvdr');
        });

        let allChartContainers;
        let allGenerators;
        let allSeries;
        let charts;
        // if (userDetail.permissions.permission.chatBot.chart9ch) {
        // if (userDetail.permissions && userDetail.permissions.permission && userDetail.permissions.permission.chatBot && userDetail.permissions.permission.chatBot.chart9ch) {
        console.log(userDetail.permissions)
        console.log(userDetail.permissions && userDetail.permissions.permission && userDetail.permissions.permission.chatBot9Chanel && userDetail.permissions.permission.chatBot9Chanel.read)
        if (userDetail.permissions && userDetail.permissions.permission && userDetail.permissions.permission.chatBot9Chanel && userDetail.permissions.permission.chatBot9Chanel.read) {
            // allChartContainers = [chartContainer1, chartContainer2, chartContainer4, chartContainer5, chartContainer6,
            //     chartContainer8, chartContainer9, chartContainer10, chartContainer11];
            // allGenerators = [generateChartLast, generateChartPeak, generateChartFlow, generateChart59, generateChart246,
            //     generateChartShort, generateChartFlowBidask, generateChartTick, generateChartTransac];
            // allSeries = [series1, series2, series4, series5, series6, series8, series9, series10, series11];
            // charts = [chart1, chart2, chart4, chart5, chart6, chart8, chart9, chart10, chart11];

            allChartContainers = [chartContainer1, chartContainer2, chartContainer4, chartContainer5, chartContainer6,
                chartContainer8];
            allGenerators = [generateChartLast, generateChartPeak, generateChartFlow, generateChart59, generateChart246,
                generateChartShort];
            allSeries = [series1, series2, series4, series5, series6, series8];
            charts = [chart1, chart2, chart4, chart5, chart6, chart8];
        } else {
            // allChartContainers = [chartContainer1, chartContainer4, chartContainer5, chartContainer6,
            //     chartContainer8, chartContainer9, chartContainer10, chartContainer11];
            // allGenerators = [generateChartLast, generateChartFlow, generateChart59, generateChart246,
            //     generateChartShort, generateChartFlowBidask, generateChartTick, generateChartTransac];
            // allSeries = [series1, series4, series5, series6, series8, series9, series10, series11];
            // charts = [chart1, chart4, chart5, chart6, chart8, chart9, chart10, chart11];

            allChartContainers = [chartContainer1, chartContainer4, chartContainer5, chartContainer6,
                chartContainer8];
            allGenerators = [generateChartLast, generateChartFlow, generateChart59, generateChart246,
                generateChartShort];
            allSeries = [series1, series4, series5, series6, series8];
            charts = [chart1, chart4, chart5, chart6, chart8];
        }
        // const allChartContainers = [chartContainer1, chartContainer2, ];
        // const allGenerators = [generateChartLast, generateChartFlow, ];
        // const allSeries = [series1, series2, ];
        // const charts = [chart1, chart2, ];


        function syncChart() {
            charts.forEach((currentChart, currentIndex) => {
                currentChart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
                    charts.forEach((otherChart, otherIndex) => {
                        if (currentIndex !== otherIndex) {
                            otherChart.timeScale().setVisibleLogicalRange(timeRange);
                        }
                    });
                });
            });

            charts.forEach((currentChart, currentIndex) => {
                currentChart.subscribeCrosshairMove(param => {
                    if (!allSeries[currentIndex] || !allSeries[currentIndex][0]) return;
                    const dataPoint = getCrosshairDataPoint(allSeries[currentIndex][0], param);
                    if (!dataPoint) return;

                    charts.forEach((otherChart, otherIndex) => {
                        if (currentIndex !== otherIndex && allSeries[otherIndex] && allSeries[otherIndex][0]) {
                            allSeries[otherIndex].forEach(otherSeries => {
                                syncCrosshair(otherChart, otherSeries, dataPoint);
                            });
                        }
                    });
                });
            });
        }


        syncChart()
        window.syncChart = syncChart;

        function renderChartInNewWindow(chartContainer, generateChart, symbol) {
            // Specify window dimensions and position
            const windowWidth = 800;
            const windowHeight = 600;
            const windowLeft = window.screen.width / 2 - windowWidth / 2;
            const windowTop = window.screen.height / 2 - windowHeight / 2;

            // Open a new browser window
            const newWindow = window.open('', 'Chart Window', `width=${windowWidth},height=${windowHeight},left=${windowLeft},top=${windowTop}`);

            // Write the basic HTML structure
            newWindow.document.write('<html><head><title>Chart Popup</title></head><body></body></html>');

            // Create a div container
            const container = newWindow.document.createElement('div');
            container.classList.add('grid', 'grid-cols-[1fr,3fr]', 'grid-rows-1', 'gap-2');
            container.style.width = '100%'; // Ensure container fills the width
            container.style.height = '100vh'; // Ensure container fills the height


            // Append the chart container to the new window
            const nameContainer = newWindow.document.createElement('div');
            const symbolName = newWindow.document.createElement('h2');
            symbolName.textContent = symbol; // Ensure 'symbol' is defined
            nameContainer.appendChild(symbolName);
            container.appendChild(nameContainer);
            container.appendChild(chartContainer);

            // Ensure that the chart and series are interactive after scripts are loaded
            newWindow.addEventListener('DOMContentLoaded', () => {
                // Reinitialize or modify the chart/series in the new window
                const [newChart, newSeries, newChartContainer] = generateChart();
                container.innerHTML = ''; // Clear previous content
                container.appendChild(newChartContainer);

                // Resize the chart to fit its container
                newChart.resize(newChartContainer.clientWidth, newChartContainer.clientHeight);

                // Handle window resize to resize the chart accordingly
                const handleResize = () => {
                    newChart.resize(newChartContainer.clientWidth, newChartContainer.clientHeight);
                };

                newWindow.addEventListener('resize', handleResize);

                // Cleanup the resize event listener when the window is closed
                newWindow.addEventListener('beforeunload', () => {
                    newWindow.removeEventListener('resize', handleResize);
                });
            });
            newWindow.document.body.appendChild(container);

        }

        allChartContainers.forEach((container, index) => {
            //addRenderButton(allGenerators[index], container);
            responseContainer.appendChild(container);
        });
        // for (let index = 0; index < 9; index++) {
        //     var xx = document.createElement('div');//'<div class="item' + (index + 1) + '">' + (index + 1) + '</div>'
        //     xx.innerHTML = (index + 1).toString();
        //     responseContainer.appendChild(xx);
        // }
        const divElements = responseContainer.querySelectorAll("div.tv-lightweight-charts > table > tr:nth-child(2) > td:nth-child(2) > div");

        divElements.forEach(element => {
            //     // Apply the desired style to each div element here
            //     // element.style.width = '636px';
            //     element.style.width = '635px';
            //     element.style.height = '100%';
            //     element.style.font = '36px';
            //     element.style.position = 'relative';
            //     element.style.overflow = 'hidden';
            //     element.style.margin = '0 auto';
        });

        return responseContainer;
    }
    const displayResponse = (response, user_input) => {
        const chatbox = document.getElementById('chatbox');

        // Create a container for the new response
        const responseContainer = document.createElement('div');
        responseContainer.classList.add('response-container');

        // Generate download button for CSV
        const downloadButton = document.createElement('button');
        downloadButton.classList.add('download-button');
        downloadButton.innerHTML = '👇Export as CSV';
        downloadButton.onclick = function () {
            exportCsv(response.csv_data, user_input); // Use the CSV data received from the server
        };
        responseContainer.appendChild(downloadButton);
        // Display bot response table
        const botResponseContainer = document.createElement('div');
        botResponseContainer.innerHTML = response.bot_response;
        responseContainer.appendChild(botResponseContainer);



        // Append the new response container to the chatbox
        chatbox.appendChild(responseContainer);

        // Initialize DataTable on the response table
        initializeDataTable(botResponseContainer);
    }
    const signalResponse = (response) => {
        const chatbox = document.getElementById('chatbox');

        // Create a container for the new response
        const responseContainer = document.createElement('div');
        responseContainer.classList.add('flex', 'flex-row', 'space-x-4');

        // Create a container for the left side (A Pattern)
        const leftContainer = document.createElement('div');
        leftContainer.classList.add('w-1/2');

        const aHeader = document.createElement('p');
        aHeader.classList.add('text-black', 'font-semibold');
        aHeader.textContent = `A Pattern`;

        const downloadAButton = document.createElement('button');
        downloadAButton.classList.add('bg-blue-500', 'text-white', 'px-4', 'py-2', 'rounded', 'hover:bg-blue-600');
        downloadAButton.innerHTML = '👇Export A as CSV';
        downloadAButton.onclick = function () {
            exportCsv(response.csv_data[1], 'a_pattern'); // Use the CSV data received from the server
        };

        const aresponse = document.createElement('div');
        aresponse.innerHTML = response.signal_response[1];

        leftContainer.appendChild(aHeader);
        leftContainer.appendChild(downloadAButton);
        leftContainer.appendChild(aresponse);

        // Create a container for the right side (V Pattern)
        const rightContainer = document.createElement('div');
        rightContainer.classList.add('w-1/2');

        const vHeader = document.createElement('p');
        vHeader.classList.add('text-black', 'font-semibold');
        vHeader.textContent = `V Pattern`;

        const downloadVButton = document.createElement('button');
        downloadVButton.classList.add('bg-blue-500', 'text-white', 'px-4', 'py-2', 'rounded', 'hover:bg-blue-600');
        downloadVButton.innerHTML = '👇Export V as CSV';
        downloadVButton.onclick = function () {
            exportCsv(response.csv_data[0], 'v_pattern'); // Use the CSV data received from the server
        };

        const vresponse = document.createElement('div');
        vresponse.innerHTML = response.signal_response[0];

        rightContainer.appendChild(vHeader);
        rightContainer.appendChild(downloadVButton);
        rightContainer.appendChild(vresponse);

        // Append left and right containers to the main response container
        responseContainer.appendChild(leftContainer);
        responseContainer.appendChild(rightContainer);

        // Append the new response container to the chatbox
        chatbox.appendChild(responseContainer);

        // Initialize DataTable on the response tables
        initializeDataTable(aresponse);
        initializeDataTable(vresponse);
    }
    const marketSummaryPlot = (response) => {
        const chatbox = document.getElementById('chatbox');
        const plotData = response.marketsummary_data;
        const symbol = plotData[4];
        // Create container for charts and tooltip
        const responseContainer = document.createElement('div');
        responseContainer.setAttribute('class', 'grid grid-cols-3 grid-rows-4 gap-2 p-4');
        // responseContainer.setAttribute('style', 'grid-template-columns: repeat(4, 500px); grid-template-rows: repeat(2, 250px);');
        const containerWidth = '750px';
        const containerHeight = '250px';
        // responseContainer.setAttribute('id', 'sortable-grid');
        responseContainer.classList.add('response-container');
        responseContainer.style.gridTemplateColumns = `repeat(3, ${containerWidth})`;
        responseContainer.style.gridTemplateRows = `repeat(4, ${containerHeight})`;
        const chartWidth = 750;
        const chartHeight = 250;
        const chartOption = { width: chartWidth, height: chartHeight, crosshair: { mode: 1, }, timeScale: { visible: true }, layout: { background: { type: 'solid', color: '#f5f5f5' } } }

        function chartAnnotation(chart, text) {
            chart.applyOptions({
                watermark: {
                    visible: true,
                    fontSize: 24,
                    horzAlign: 'left',
                    vertAlign: 'top',
                    color: 'rgb(1,216,149)',
                    text: text,
                },
                rightPriceScale: {
                    visible: true,
                },
                leftPriceScale: {
                    visible: true,
                    mode: LightweightCharts.PriceScaleMode.Normal,
                    autoScale: false,  // Disable automatic scaling
                    invertScale: false,
                    alignLabels: true,
                    minValue: -1,      // Set the minimum value
                    maxValue: 1,       // Set the maximum value
                },
            });

        }


        function generateChart1() {
            const chartContainer = document.createElement('div');
            chartContainer.classList.add('chart-container');

            chartContainer.setAttribute('id', `chart-1`)
            const chart = LightweightCharts.createChart(chartContainer, chartOption);
            chartAnnotation(chart, 'Price')

            const series = chart.addLineSeries({ title: 'close', color: '#d93025', lastValueVisible: true, priceLineVisible: false, priceScaleId: 'right', CrosshairMarkerVisible: false })
            // series.setMarkers(plotData[0]);
            const dataForSeries = generateData(plotData[0], 'time', 'value');

            series.setData(dataForSeries);
            series.applyOptions({
                priceFormat: {
                    type: 'custom',
                    formatter: (price) => { return price_formatter(price) }
                },
            });
            let max_data = plotData['max_peaks']
            let markers = []
            chart.subscribeDblClick((param) => {
                console.log('Hello plot....')
                // let max_peaks = max_data['price']['max']
                // let min_peaks = max_data['price']['min']
                // let b = false;
                // for(let i=0; i<markers.length; i++){
                //   let obj = markers[i];
                //   if(param['time'] == obj['time']){
                //     b = true;
                //     markers.splice(i, 1); 
                //   }
                // }
                // if(!b){
                //     console.log('Hello')
                // //   console.log(param['value'], max_peaks.includes(param['value']))
                //   let bMax = false
                //   let bMin = false
                //   for (let [key, value] of param['seriesData']) {
                //     if(max_peaks.includes(value['value'])){
                //         bMax = true
                //     } else if (min_peaks.includes(value['value'])){
                //         bMin = true
                //     }
                //   }
                // //   console.log('b', bMax, bMin)
                //   let colorCode = ''
                //   let charCode = ''
                //   if(bMax){
                //     colorCode = '#008000'
                //     charCode = 'U'
                //   } else if(bMin){
                //     colorCode = '#FF0000'
                //     charCode = 'D'
                //   }
                //   markers = [...markers, {
                //     'time': param['time'],
                //     position: 'aboveBar',
                //     color: colorCode,
                //     shape: 'circle',
                //     text: charCode
                //   }]
                // }
                // function compare( a, b ) {
                //     if ( a.time < b.time ){
                //       return -1;
                //     }
                //     if ( a.time > b.time ){
                //       return 1;
                //     }
                //     return 0;
                // }
                // markers.sort(compare)
                // series3_1.setMarkers(markers)
            });
            return [chart, [series], chartContainer]
        }
        function generateChart2() {
            const chartContainer = document.createElement('div');
            chartContainer.classList.add('chart-container');

            chartContainer.setAttribute('id', `chart-2`)
            const chart = LightweightCharts.createChart(chartContainer, chartOption);
            chartAnnotation(chart, 'Open Interest')

            const series = chart.addLineSeries({ title: 'oi', color: '#ffc107', lastValueVisible: true, priceLineVisible: false, priceScaleId: 'right', CrosshairMarkerVisible: false })
            // series.setMarkers(plotData[0]);
            const dataForSeries = generateData(plotData[1], 'time', 'value');

            series.setData(dataForSeries);
            series.applyOptions({
                priceFormat: {
                    type: 'volume',
                }
            })

            return [chart, [series], chartContainer]
        }
        function generateChart3() {
            const chartContainer = document.createElement('div');
            chartContainer.classList.add('chart-container');

            chartContainer.setAttribute('id', `chart-3`)
            const chart = LightweightCharts.createChart(chartContainer, chartOption);
            chartAnnotation(chart, 'Volume')

            const series = chart.addLineSeries({ title: 'volume', color: '#28a745', lastValueVisible: true, priceLineVisible: false, priceScaleId: 'right', CrosshairMarkerVisible: false })
            // series.setMarkers(plotData[0]);
            const dataForSeries = generateData(plotData[2], 'time', 'value');

            series.setData(dataForSeries);
            series.applyOptions({
                priceFormat: {
                    type: 'volume',
                }
            })

            return [chart, [series], chartContainer]
        }

        function generateChart4() {
            const chartContainer = document.createElement('div');
            chartContainer.classList.add('chart-container');
            const chart = LightweightCharts.createChart(chartContainer, chartOption);
            chartAnnotation(chart, 'Market Summary')

            const market_summay_opt = { priceLineVisible: false, priceScaleId: 'right' };

            const series4_1 = chart.addLineSeries({ color: '#1a73e8', lineStyle: 0, lineWidth: 3, title: 'สถาบัน', ...market_summay_opt });
            const series4_2 = chart.addLineSeries({ color: '#00ff00', lineStyle: 0, lineWidth: 3, title: 'ต่างชาติ', ...market_summay_opt });
            const series4_3 = chart.addLineSeries({ color: '#ff6600', lineStyle: 0, lineWidth: 3, title: 'ในประเทศ', ...market_summay_opt });

            const series = [series4_1, series4_2, series4_3]
            series.forEach((series, index) => {
                const dataForSeries = generateData(plotData[3][index], 'time', 'value');
                series.setData(dataForSeries)
                series.applyOptions({
                    priceFormat: {
                        type: 'volume',
                    }
                })
            });

            return [chart, series, chartContainer]
        }

        const [chart1, series1, chartContainer1] = generateChart1();
        const [chart2, series2, chartContainer2] = generateChart2();
        const [chart3, series3, chartContainer3] = generateChart3();
        const [chart4, series4, chartContainer4] = generateChart4();

        const allSeries = [series1, series2, series3, series4];
        const charts = [chart1, chart2, chart3, chart4];
        charts.forEach((currentChart, currentIndex) => {
            currentChart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
                charts.forEach((otherChart, otherIndex) => {
                    if (currentIndex !== otherIndex) {
                        otherChart.timeScale().setVisibleLogicalRange(timeRange);
                    }
                });
            });
        });

        charts.forEach((currentChart, currentIndex) => {
            currentChart.subscribeCrosshairMove(param => {
                const dataPoint = getCrosshairDataPoint(allSeries[currentIndex][0], param);

                charts.forEach((otherChart, otherIndex) => {
                    if (currentIndex !== otherIndex) {
                        allSeries[otherIndex].forEach(otherSeries => {
                            syncCrosshair(otherChart, otherSeries, dataPoint);
                        });
                    }
                });
            });
        });

        function renderChartInNewWindow(chartContainer, generateChart) {
            // Specify window dimensions and position
            const windowWidth = 800;
            const windowHeight = 600;
            const windowLeft = window.screen.width / 2 - windowWidth / 2;
            const windowTop = window.screen.height / 2 - windowHeight / 2;

            // Open a new browser window
            const newWindow = window.open('', 'Chart Window', `width=${windowWidth},height=${windowHeight},left=${windowLeft},top=${windowTop}`);

            // Write the basic HTML structure
            newWindow.document.write('<html><head><title>Chart Popup</title></head><body></body></html>');

            // Create a div container
            const container = newWindow.document.createElement('div');
            container.classList.add('grid', 'grid-cols-[1fr,3fr]', 'grid-rows-1', 'gap-2');
            newWindow.document.body.appendChild(container);

            // Add Tailwind CSS
            const tailwindLink = newWindow.document.createElement('link');
            tailwindLink.href = 'https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css';
            tailwindLink.rel = 'stylesheet';
            newWindow.document.head.appendChild(tailwindLink);

            // Append the chart container to the new window
            const nameContainer = newWindow.document.createElement('div');
            const symbolName = newWindow.document.createElement('h2');
            symbolName.textContent = symbol; // Ensure 'symbol' is defined
            nameContainer.appendChild(symbolName);
            container.appendChild(nameContainer);
            container.appendChild(chartContainer);

            // Create a button and append it to the body
            const addButton = newWindow.document.createElement('button');
            addButton.textContent = 'Load Scripts';
            addButton.addEventListener('click', () => {
                // Add script tags dynamically to the body when the button is clicked
                const scriptPaths = [
                    '/static/config.js',
                    'https://code.jquery.com/jquery-3.6.4.min.js',
                    'https://cdn.datatables.net/1.11.5/js/jquery.dataTables.min.js',
                    'https://unpkg.com/lightweight-charts/dist/lightweight-charts.standalone.production.js',
                    '/static/global.js',
                    '/static/newdemo.js',
                    '/static/demo.js',
                    '/static/market_summary.js'
                ];

                scriptPaths.forEach((scriptPath) => {
                    const scriptElement = newWindow.document.createElement('script');
                    scriptElement.src = scriptPath;
                    newWindow.document.body.appendChild(scriptElement);
                });
            });

            newWindow.document.body.appendChild(addButton);

            // Ensure that the chart and series are interactive after scripts are loaded
            newWindow.addEventListener('DOMContentLoaded', () => {
                // Reinitialize or modify the chart/series in the new window
                const [newChart, newSeries, newChartContainer] = generateChart();
                container.innerHTML = ''; // Clear previous content
                container.appendChild(newChartContainer);
            });
        }

        function addRenderButton(generateChart, chartContainer) {
            const [chart, series, container] = generateChart();
            document.querySelectorAll('.newtabButton').forEach(button => {
                button.removeEventListener('click', () => renderChartInNewWindow(container, generateChart));
            });
            const renderButton = document.createElement('button');
            renderButton.setAttribute('class', 'btn');
            renderButton.setAttribute('style', 'z-index:50; position:absolute;');

            renderButton.classList.add("newtabButton");
            renderButton.textContent = '🔎';
            // renderButton.addEventListener('click', () => renderChartInNewWindow(container, generateChart));

            // const renderButtonContainer = document.createElement('div');
            // renderButtonContainer.setAttribute('style','z-index:999; position:relative;')
            // renderButtonContainer.appendChild(renderButton);
            // chartContainer.insertBefore(renderButtonContainer, chartContainer.firstChild);

            renderButton.addEventListener('click', clickHandler);

            const renderButtonContainer = document.createElement('div');
            renderButtonContainer.setAttribute('style', 'z-index:999; position:relative;')
            renderButtonContainer.appendChild(renderButton);
            chartContainer.insertBefore(renderButtonContainer, chartContainer.firstChild);

            function clickHandler() {
                renderChartInNewWindow(container, generateChart, symbol);
            }
        }

        const allChartContainers = [chartContainer1, chartContainer2, chartContainer3, chartContainer4];
        const allGenerators = [generateChart1, generateChart2, generateChart3, generateChart4];

        allChartContainers.forEach((container, index) => {
            addRenderButton(allGenerators[index], container);
            responseContainer.appendChild(container);
        });

        chatbox.appendChild(responseContainer);
    }

    const petroPlot = (response) => {
        const chatbox = document.getElementById('chatbox');
        const plotData = response.petroleum_data;
        log(plotData)
        const symbol = plotData[4];
        // Create container for charts and tooltip
        const responseContainer = document.createElement('div');
        responseContainer.setAttribute('class', 'grid grid-cols-3 grid-rows-4 gap-2 p-4');
        // responseContainer.setAttribute('style', 'grid-template-columns: repeat(4, 500px); grid-template-rows: repeat(2, 250px);');
        const containerWidth = '750px';
        const containerHeight = '250px';
        // responseContainer.setAttribute('id', 'sortable-grid');
        responseContainer.classList.add('response-container');
        responseContainer.style.gridTemplateColumns = `repeat(3, ${containerWidth})`;
        responseContainer.style.gridTemplateRows = `repeat(4, ${containerHeight})`;
        const chartWidth = 750;
        const chartHeight = 250;
        const chartOption = { width: chartWidth, height: chartHeight, crosshair: { mode: 1, }, timeScale: { visible: true }, layout: { background: { type: 'solid', color: '#f5f5f5' } } }

        function chartAnnotation(chart, text) {
            chart.applyOptions({
                watermark: {
                    visible: true,
                    fontSize: 24,
                    horzAlign: 'left',
                    vertAlign: 'top',
                    color: 'rgb(1,216,149)',
                    text: text,
                },
                rightPriceScale: {
                    visible: true,
                },
                leftPriceScale: {
                    visible: true,
                    mode: LightweightCharts.PriceScaleMode.Normal,
                    autoScale: false,  // Disable automatic scaling
                    invertScale: false,
                    alignLabels: true,
                    minValue: -1,      // Set the minimum value
                    maxValue: 1,       // Set the maximum value
                },
            });

        }


        function generateChart1() {
            const chartContainer = document.createElement('div');
            chartContainer.classList.add('chart-container');

            chartContainer.setAttribute('id', `chart-1`)
            const chart = LightweightCharts.createChart(chartContainer, chartOption);
            chartAnnotation(chart, 'Price')

            const series = chart.addLineSeries({ title: 'close', color: '#d93025', lastValueVisible: true, priceLineVisible: false, priceScaleId: 'right', CrosshairMarkerVisible: false })
            // series.setMarkers(plotData[0]);
            const dataForSeries = generateData(plotData[0], 'time', 'value');

            series.setData(dataForSeries);
            series.applyOptions({
                priceFormat: {
                    type: 'custom',
                    formatter: (price) => { return price_formatter(price) }
                },
            });

            let max_data = plotData['max_peaks']
            let markers = []
            chart.subscribeDblClick((param) => {
                console.log('Hello click')
                // let max_peaks = max_data['price']['max']
                // let min_peaks = max_data['price']['min']
                // let b = false;
                // for(let i=0; i<markers.length; i++){
                //   let obj = markers[i];
                //   if(param['time'] == obj['time']){
                //     b = true;
                //     markers.splice(i, 1); 
                //   }
                // }
                // if(!b){
                //     console.log('Hello')
                // //   console.log(param['value'], max_peaks.includes(param['value']))
                //   let bMax = false
                //   let bMin = false
                //   for (let [key, value] of param['seriesData']) {
                //     if(max_peaks.includes(value['value'])){
                //         bMax = true
                //     } else if (min_peaks.includes(value['value'])){
                //         bMin = true
                //     }
                //   }
                // //   console.log('b', bMax, bMin)
                //   let colorCode = ''
                //   let charCode = ''
                //   if(bMax){
                //     colorCode = '#008000'
                //     charCode = 'U'
                //   } else if(bMin){
                //     colorCode = '#FF0000'
                //     charCode = 'D'
                //   }
                //   markers = [...markers, {
                //     'time': param['time'],
                //     position: 'aboveBar',
                //     color: colorCode,
                //     shape: 'circle',
                //     text: charCode
                //   }]
                // }
                // function compare( a, b ) {
                //     if ( a.time < b.time ){
                //       return -1;
                //     }
                //     if ( a.time > b.time ){
                //       return 1;
                //     }
                //     return 0;
                // }
                // markers.sort(compare)
                // series3_1.setMarkers(markers)
            });
            return [chart, [series], chartContainer]
        }

        const filterContainer = document.createElement('div');
        filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index:3;')
        const buttonContainer = document.createElement('div');
        buttonContainer.setAttribute('style', 'padding-left: 20%; position: absolute; z-index: 1;"')

        function generateChart2() {
            const chartContainer = document.createElement('div');
            chartContainer.classList.add('chart-container');
            const chart = LightweightCharts.createChart(chartContainer, chartOption);
            chartAnnotation(chart, 'EX-REFIN')

            const ex_refin_opt = { priceLineVisible: false, priceScaleId: 'right' };
            let numberOfColors = plotData[1].length;
            uniqueRandomColors = generateUniqueRandomColors(numberOfColors);
            var series2_arr = new Array;
            plotData[1].forEach((value, index) => {
                series2_arr.push(chart.addLineSeries({ color: uniqueRandomColors[index], lineStyle: 0, lineWidth: 3, title: plotData[4][index]['name'], ...ex_refin_opt }))
            });

            // const filterContainer = document.createElement('div');
            // filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index:3;')
            // const buttonContainer = document.createElement('div');
            // buttonContainer.setAttribute('style', 'padding-left: 20%; position: absolute; z-index: 1;"')

            series2_arr.forEach((series, index) => {
                const dataForSeries = generateData(plotData[1][index], 'time', 'value');
                series.setData(dataForSeries)
                series.applyOptions({
                    priceFormat: {
                        type: 'volume',
                    }
                });

                // const show_hide_serie = document.createElement('button');
                // show_hide_serie.setAttribute('class', 'btn');
                // show_hide_serie.textContent = plotData[4][index]['name'];
                // show_hide_serie.setAttribute('style', 'z-index:2;')
                // show_hide_serie.appendChild(createColorIndicator(uniqueRandomColors[index]));
                // show_hide_serie.addEventListener('click', () => {
                //     series.applyOptions({
                //         visible: !series.options().visible,
                //     });
                // });

                // buttonContainer.appendChild(show_hide_serie);
            });

            // function createColorIndicator(color) {
            //     const colorIndicator = document.createElement('span');
            //     colorIndicator.style.display = 'inline-block';
            //     colorIndicator.style.width = '10px';
            //     colorIndicator.style.height = '10px';
            //     colorIndicator.style.backgroundColor = color;
            //     colorIndicator.style.marginRight = '5px';
            //     return colorIndicator;
            // }

            // const show_hide_allserie = document.createElement('button');
            // show_hide_allserie.setAttribute('class', 'btn');
            // show_hide_allserie.textContent = 'ShowHideAll';
            // show_hide_allserie.setAttribute('style', 'z-index:2;')
            // show_hide_allserie.appendChild(createColorIndicator('black'));
            // show_hide_allserie.addEventListener('click', () => {
            //     series2_arr.forEach((series, index) => {
            //         series.applyOptions({
            //             visible: !series.options().visible,
            //         });
            //     })
            // });

            // buttonContainer.appendChild(show_hide_allserie);

            // filterContainer.appendChild(buttonContainer);
            // chartContainer.appendChild(filterContainer);

            return [chart, series2_arr, chartContainer]
        }
        function generateChart3() {
            const chartContainer = document.createElement('div');
            chartContainer.classList.add('chart-container');
            const chart = LightweightCharts.createChart(chartContainer, chartOption);
            chartAnnotation(chart, 'Marketing Margin')

            const mkt_margin_opt = { priceLineVisible: false, priceScaleId: 'right' };

            var series3_arr = new Array;
            plotData[2].forEach((value, index) => {
                series3_arr.push(chart.addLineSeries({ color: uniqueRandomColors[index], lineStyle: 0, lineWidth: 3, title: plotData[4][index]['name'], ...mkt_margin_opt }))
            });

            // const filterContainer = document.createElement('div');
            // filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index:3;')
            // const buttonContainer = document.createElement('div');
            // buttonContainer.setAttribute('style', 'padding-left: 20%; position: absolute; z-index: 1;"')

            series3_arr.forEach((series, index) => {
                const dataForSeries = generateData(plotData[2][index], 'time', 'value');
                series.setData(dataForSeries)
                series.applyOptions({
                    priceFormat: {
                        type: 'volume',
                    }
                });

                // const show_hide_serie = document.createElement('button');
                // show_hide_serie.setAttribute('class', 'btn');
                // show_hide_serie.textContent = plotData[4][index]['name'];
                // show_hide_serie.setAttribute('style', 'z-index:2;')
                // show_hide_serie.appendChild(createColorIndicator(uniqueRandomColors[index]));
                // show_hide_serie.addEventListener('click', () => {
                //     series.applyOptions({
                //         visible: !series.options().visible,
                //     });
                // });

                // buttonContainer.appendChild(show_hide_serie);
            });

            // function createColorIndicator(color) {
            //     const colorIndicator = document.createElement('span');
            //     colorIndicator.style.display = 'inline-block';
            //     colorIndicator.style.width = '10px';
            //     colorIndicator.style.height = '10px';
            //     colorIndicator.style.backgroundColor = color;
            //     colorIndicator.style.marginRight = '5px';
            //     return colorIndicator;
            // }

            // const show_hide_allserie = document.createElement('button');
            // show_hide_allserie.setAttribute('class', 'btn');
            // show_hide_allserie.textContent = 'ShowHideAll';
            // show_hide_allserie.setAttribute('style', 'z-index:2;')
            // show_hide_allserie.appendChild(createColorIndicator('black'));
            // show_hide_allserie.addEventListener('click', () => {
            //     series3_arr.forEach((series, index) => {
            //         series.applyOptions({
            //             visible: !series.options().visible,
            //         });
            //     })
            // });

            // buttonContainer.appendChild(show_hide_allserie);

            // filterContainer.appendChild(buttonContainer);
            // chartContainer.appendChild(filterContainer);

            return [chart, series3_arr, chartContainer]
        }

        function generateChart4() {
            const chartContainer = document.createElement('div');
            chartContainer.classList.add('chart-container');
            const chart = LightweightCharts.createChart(chartContainer, chartOption);
            chartAnnotation(chart, 'Oil Fund')

            const oil_fund_opt = { priceLineVisible: false, priceScaleId: 'right' };

            var series4_arr = new Array;
            plotData[3].forEach((value, index) => {
                series4_arr.push(chart.addLineSeries({ color: uniqueRandomColors[index], lineStyle: 0, lineWidth: 3, title: plotData[4][index]['name'], ...oil_fund_opt }))
            });

            // const filterContainer = document.createElement('div');
            // filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index:3;')
            // const buttonContainer = document.createElement('div');
            // buttonContainer.setAttribute('style', 'padding-left: 20%; position: absolute; z-index: 1;"')

            series4_arr.forEach((series, index) => {
                const dataForSeries = generateData(plotData[3][index], 'time', 'value');
                series.setData(dataForSeries)
                series.applyOptions({
                    priceFormat: {
                        type: 'volume',
                    }
                });

                // const show_hide_serie = document.createElement('button');
                // show_hide_serie.setAttribute('class', 'btn');
                // show_hide_serie.textContent = plotData[4][index]['name'];
                // show_hide_serie.setAttribute('style', 'z-index:2;')
                // show_hide_serie.appendChild(createColorIndicator(uniqueRandomColors[index]));
                // show_hide_serie.addEventListener('click', () => {
                //     series.applyOptions({
                //         visible: !series.options().visible,
                //     });
                // });

                // buttonContainer.appendChild(show_hide_serie);
            });

            // function createColorIndicator(color) {
            //     const colorIndicator = document.createElement('span');
            //     colorIndicator.style.display = 'inline-block';
            //     colorIndicator.style.width = '10px';
            //     colorIndicator.style.height = '10px';
            //     colorIndicator.style.backgroundColor = color;
            //     colorIndicator.style.marginRight = '5px';
            //     return colorIndicator;
            // }

            // const show_hide_allserie = document.createElement('button');
            // show_hide_allserie.setAttribute('class', 'btn');
            // show_hide_allserie.textContent = 'ShowHideAll';
            // show_hide_allserie.setAttribute('style', 'z-index:2;')
            // show_hide_allserie.appendChild(createColorIndicator('black'));
            // show_hide_allserie.addEventListener('click', () => {
            //     series4_arr.forEach((series, index) => {
            //         series.applyOptions({
            //             visible: !series.options().visible,
            //         });
            //     })
            // });

            // buttonContainer.appendChild(show_hide_allserie);

            // filterContainer.appendChild(buttonContainer);
            // chartContainer.appendChild(filterContainer);

            return [chart, series4_arr, chartContainer]
        }

        const [chart1, series1, chartContainer1] = generateChart1();
        const [chart2, series2, chartContainer2] = generateChart2();
        const [chart3, series3, chartContainer3] = generateChart3();
        const [chart4, series4, chartContainer4] = generateChart4();


        function createColorIndicator(color) {
            const colorIndicator = document.createElement('span');
            colorIndicator.style.display = 'inline-block';
            colorIndicator.style.width = '10px';
            colorIndicator.style.height = '10px';
            colorIndicator.style.backgroundColor = color;
            colorIndicator.style.marginRight = '5px';
            return colorIndicator;
        }
        series2.forEach((series, index) => {
            const show_hide_serie = document.createElement('button');
            show_hide_serie.setAttribute('class', 'btn');
            show_hide_serie.textContent = plotData[4][index]['name'];
            show_hide_serie.setAttribute('style', 'z-index:2;')
            show_hide_serie.appendChild(createColorIndicator(uniqueRandomColors[index]));
            show_hide_serie.addEventListener('click', () => {
                series.applyOptions({
                    visible: !series.options().visible,
                });
                series3[index].applyOptions({
                    visible: !series3[index].options().visible,
                });
                series4[index].applyOptions({
                    visible: !series4[index].options().visible,
                });
            });
            buttonContainer.appendChild(show_hide_serie);
        });
        const show_hide_allserie = document.createElement('button');
        show_hide_allserie.setAttribute('class', 'btn');
        show_hide_allserie.textContent = 'ShowHideAll';
        show_hide_allserie.setAttribute('style', 'z-index:2;')
        show_hide_allserie.appendChild(createColorIndicator('black'));
        show_hide_allserie.addEventListener('click', () => {
            series2.forEach((series, index) => {
                series.applyOptions({
                    visible: !series.options().visible,
                });
                series3[index].applyOptions({
                    visible: !series3[index].options().visible,
                });
                series4[index].applyOptions({
                    visible: !series4[index].options().visible,
                });
            })
        });
        buttonContainer.appendChild(show_hide_allserie);
        filterContainer.appendChild(buttonContainer);
        chartContainer4.appendChild(filterContainer);
        // chartContainer2.prepend(filterContainer)


        const allSeries = [series1, series2, series3, series4];
        const charts = [chart1, chart2, chart3, chart4];
        charts.forEach((currentChart, currentIndex) => {
            currentChart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
                charts.forEach((otherChart, otherIndex) => {
                    if (currentIndex !== otherIndex) {
                        otherChart.timeScale().setVisibleLogicalRange(timeRange);
                    }
                });
            });
        });

        charts.forEach((currentChart, currentIndex) => {
            currentChart.subscribeCrosshairMove(param => {
                const dataPoint = getCrosshairDataPoint(allSeries[currentIndex][0], param);

                charts.forEach((otherChart, otherIndex) => {
                    if (currentIndex !== otherIndex) {
                        allSeries[otherIndex].forEach(otherSeries => {
                            syncCrosshair(otherChart, otherSeries, dataPoint);
                        });
                    }
                });
            });
        });

        function renderChartInNewWindow(chartContainer, generateChart) {
            // Specify window dimensions and position
            const windowWidth = 800;
            const windowHeight = 600;
            const windowLeft = window.screen.width / 2 - windowWidth / 2;
            const windowTop = window.screen.height / 2 - windowHeight / 2;

            // Open a new browser window
            const newWindow = window.open('', 'Chart Window', `width=${windowWidth},height=${windowHeight},left=${windowLeft},top=${windowTop}`);

            // Write the basic HTML structure
            newWindow.document.write('<html><head><title>Chart Popup</title></head><body></body></html>');

            // Create a div container
            const container = newWindow.document.createElement('div');
            container.classList.add('grid', 'grid-cols-[1fr,3fr]', 'grid-rows-1', 'gap-2');
            newWindow.document.body.appendChild(container);

            // Add Tailwind CSS
            const tailwindLink = newWindow.document.createElement('link');
            tailwindLink.href = 'https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css';
            tailwindLink.rel = 'stylesheet';
            newWindow.document.head.appendChild(tailwindLink);

            // Append the chart container to the new window
            const nameContainer = newWindow.document.createElement('div');
            const symbolName = newWindow.document.createElement('h2');
            symbolName.textContent = symbol; // Ensure 'symbol' is defined
            nameContainer.appendChild(symbolName);
            container.appendChild(nameContainer);
            container.appendChild(chartContainer);

            // Create a button and append it to the body
            const addButton = newWindow.document.createElement('button');
            addButton.textContent = 'Load Scripts';
            addButton.addEventListener('click', () => {
                // Add script tags dynamically to the body when the button is clicked
                const scriptPaths = [
                    '/static/config.js',
                    'https://code.jquery.com/jquery-3.6.4.min.js',
                    'https://cdn.datatables.net/1.11.5/js/jquery.dataTables.min.js',
                    'https://unpkg.com/lightweight-charts/dist/lightweight-charts.standalone.production.js',
                    '/static/global.js',
                    '/static/newdemo.js',
                    '/static/demo.js',
                    '/static/market_summary.js'
                ];

                scriptPaths.forEach((scriptPath) => {
                    const scriptElement = newWindow.document.createElement('script');
                    scriptElement.src = scriptPath;
                    newWindow.document.body.appendChild(scriptElement);
                });
            });

            newWindow.document.body.appendChild(addButton);

            // Ensure that the chart and series are interactive after scripts are loaded
            newWindow.addEventListener('DOMContentLoaded', () => {
                // Reinitialize or modify the chart/series in the new window
                const [newChart, newSeries, newChartContainer] = generateChart();
                container.innerHTML = ''; // Clear previous content
                container.appendChild(newChartContainer);
            });
        }

        function addRenderButton(generateChart, chartContainer) {
            const [chart, series, container] = generateChart();
            document.querySelectorAll('.newtabButton').forEach(button => {
                button.removeEventListener('click', () => renderChartInNewWindow(container, generateChart));
            });
            const renderButton = document.createElement('button');
            renderButton.setAttribute('class', 'btn');
            renderButton.setAttribute('style', 'z-index:50; position:absolute;');

            renderButton.classList.add("newtabButton");
            renderButton.textContent = '🔎';
            // renderButton.addEventListener('click', () => renderChartInNewWindow(container, generateChart));

            // const renderButtonContainer = document.createElement('div');
            // renderButtonContainer.setAttribute('style','z-index:999; position:relative;')
            // renderButtonContainer.appendChild(renderButton);
            // chartContainer.insertBefore(renderButtonContainer, chartContainer.firstChild);

            renderButton.addEventListener('click', clickHandler);

            const renderButtonContainer = document.createElement('div');
            renderButtonContainer.setAttribute('style', 'z-index:999; position:relative;')
            renderButtonContainer.appendChild(renderButton);
            chartContainer.insertBefore(renderButtonContainer, chartContainer.firstChild);

            function clickHandler() {
                renderChartInNewWindow(container, generateChart, symbol);
            }
        }

        const allChartContainers = [chartContainer1, chartContainer2, chartContainer3, chartContainer4];
        const allGenerators = [generateChart1, generateChart2, generateChart3, generateChart4];

        allChartContainers.forEach((container, index) => {
            addRenderButton(allGenerators[index], container);
            responseContainer.appendChild(container);
        });

        chatbox.appendChild(responseContainer);
    }
    const setChartplot = (response) => {
        const chatbox = document.getElementById('chatbox');
        const plotData = response.index_data;
        window.plotData = plotData;
        const symbol = plotData['index'];

        // const containerWidth = '750px';
        // const containerHeight = '250px';
        const containerWidth = 'auto';
        const containerHeight = 'auto';

        const responseContainer = document.createElement('div');
        if (symbol === 'SET50') {
            responseContainer.setAttribute('class', 'grid grid-cols-3 grid-rows-3 gap-2 p-4');
            // responseContainer.setAttribute('id', 'sortable-grid');
            responseContainer.classList.add('response-container');
            responseContainer.style.display = 'grid';
            responseContainer.style.gridTemplateColumns = `repeat(2, ${containerWidth})`;
            // responseContainer.style.gridTemplateColumns = `repeat(3, ${containerWidth})`;
            responseContainer.style.gridTemplateRows = `repeat(3, ${containerHeight})`;
        } else {
            responseContainer.setAttribute('class', 'grid grid-cols-3 grid-rows-2 gap-2 p-4');
            responseContainer.classList.add('response-container');
            responseContainer.style.display = 'grid';
            responseContainer.style.gridTemplateColumns = `repeat(3, ${containerWidth})`;
            // responseContainer.style.gridTemplateColumns = `repeat(2, ${containerWidth})`;
            responseContainer.style.gridTemplateRows = `repeat(2, ${containerHeight})`;
        }



        // const chartWidth = 750;
        // const chartHeight = 250;
        // const chartWidth = chatbox.offsetWidth / 3.2;
        const chartWidth = chatbox.offsetWidth / 2.1;
        const chartHeight = chatbox.offsetHeight / 3;
        const chartOption = { width: chartWidth, height: chartHeight, crosshair: { mode: 1, }, timeScale: { visible: true }, layout: { background: { type: 'solid', color: '#f5f5f5' } } }

        function chartAnnotation(chart, text) {


            chart.applyOptions({
                watermark: {
                    visible: true,
                    fontSize: 24,
                    horzAlign: 'left',
                    vertAlign: 'top',
                    color: 'rgb(1,216,149)',
                    text: text,
                },
                rightPriceScale: {
                    visible: true,
                },
                leftPriceScale: {
                    visible: true,
                    mode: PriceScaleMode.Normal,
                    autoScale: false,  // Disable automatic scaling
                    invertScale: false,
                    alignLabels: true,
                    minValue: -1,      // Set the minimum value
                    maxValue: 1,       // Set the maximum value
                },
            });



        }
        function generateIndexLast() {
            const chartIndexContainer = document.createElement('div');
            chartIndexContainer.classList.add('chart-container');

            chartIndexContainer.setAttribute('id', `chart-1`)
            const chart = createChart(chartIndexContainer, chartOption);
            chartAnnotation(chart, 'Last')
            chart.applyOptions({
                rightPriceScale: {
                    visible: true,
                },
                leftPriceScale: {
                    visible: true,
                },
            });

            const series = chart.addLineSeries({ title: '', color: 'black', lastValueVisible: true, priceLineVisible: false, priceScaleId: 'right', CrosshairMarkerVisible: false })
            const dataForSeries = generateData(plotData['price'][0], 'time', 'value');
            series.setData(dataForSeries);
            let s50_series = [];
            if (plotData['price'].length > 1) {
                s50_series_close = chart.addLineSeries({ title: '', color: 'purple', lastValueVisible: true, priceLineVisible: false, priceScaleId: 'right', CrosshairMarkerVisible: false })
                const dataForSeries = generateData(plotData['price'][1], 'time', 'value');
                s50_series_close.setData(dataForSeries);
                s50_series.push(s50_series_close)
            } else {

            }


            return [chart, [series, ...s50_series], chartIndexContainer]
        }

        function generateIndexChartS50Last() {
            const chartIndexContainer = document.createElement('div');
            chartIndexContainer.classList.add('chart-container');
            chartIndexContainer.setAttribute('id', `chart-3`);

            const filterContainer = document.createElement('div');
            filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index: 3;');
            chartIndexContainer.appendChild(filterContainer);
            const chart = createChart(chartIndexContainer, chartOption);
            chartAnnotation(chart, 'Trend') //Foreign & Individual
            let series = [];
            let turnoverSeries = [];
            if (plotData['s50_ind']['mksum_s50']) {
                chart.applyOptions({
                    rightPriceScale: {
                        visible: true,
                    },
                    leftPriceScale: {
                        visible: true,
                    },
                });

                const seriesForeign = chart.addLineSeries({
                    // title: 'foreign',
                    priceScaleId: 'right',
                    color: 'blue'
                });
                // const seriesIndividual = chart.addLineSeries({
                //     title: 'individual',
                //     priceScaleId: 'right',
                //     color: 'red'
                // });

                // const seriesInstitution = chart.addLineSeries({ 
                //     title: 'institution',
                //     priceScaleId: 'left',
                //     color: 'purple'
                // });   

                const foreignData = generateData(plotData['s50_ind']['mksum_s50'][0], 'time', 'value');
                seriesForeign.setData(foreignData);

                // const individualData = generateData(plotData['s50_ind']['mksum_s50'][1], 'time', 'value');
                // seriesIndividual.setData(individualData);

                // const institutionData = generateData(plotData['s50_ind']['mksum_s50'][2], 'time', 'value');
                // seriesInstitution.setData(institutionData);

                const mksumSeries = [seriesForeign];

                mksumSeries.forEach(serie => {
                    serie.applyOptions({
                        priceFormat: {
                            type: 'volume',
                        }
                    });
                    series.push(serie);
                });

                const turnoverForeign = chart.addLineSeries({
                    title: 'eq_foreign',
                    priceScaleId: 'right',
                    color: 'blue'
                });
                // const turnoverIndividual = chart.addLineSeries({
                //     title: 'eq_individual',
                //     priceScaleId: 'right',
                //     color: 'red'
                // });

                // const turnoverInstitution = chart.addLineSeries({ 
                //     title: 'turnover_institution',
                //     priceScaleId: 'left',
                //     color: 'purple'
                // });  

                const turnoverForeignData = generateData(plotData['s50_ind']['mksum_s50'][3], 'time', 'value');
                turnoverForeign.setData(turnoverForeignData);

                // const turnoverIndividualData = generateData(plotData['s50_ind']['mksum_s50'][4], 'time', 'value');
                // turnoverIndividual.setData(turnoverIndividualData);

                // const turnoverInstitutionData = generateData(plotData['s50_ind']['mksum_s50'][5], 'time', 'value');
                // turnoverInstitution.setData(turnoverInstitutionData);

                const turnoverMksumSeries = [turnoverForeign];
                turnoverMksumSeries.forEach(serie => {
                    serie.applyOptions({
                        priceFormat: {
                            type: 'volume',
                        },
                        visible: false
                    });
                    turnoverSeries.push(serie);
                });

                const buttonContainer = document.createElement('div');
                buttonContainer.setAttribute('style', 'padding-left: 80%; position: absolute; z-index: 1;');

                const eq = hideSeriesButton(turnoverMksumSeries, 'Eq', '#FF4D02');
                const s50 = hideSeriesButton(mksumSeries, 'S50', '#FF4D02');

                buttonContainer.appendChild(eq);
                buttonContainer.appendChild(s50);

                filterContainer.appendChild(buttonContainer);
            } else {

            }

            return [chart, [...series, ...turnoverSeries], chartIndexContainer];
        }

        function generateIndexChartS50Last() {
            const chartIndexContainer = document.createElement('div');
            chartIndexContainer.classList.add('chart-container');
            chartIndexContainer.setAttribute('id', `chart-3`);

            const filterContainer = document.createElement('div');
            filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index: 3;');
            chartIndexContainer.appendChild(filterContainer);
            const chart = createChart(chartIndexContainer, chartOption);
            chartAnnotation(chart, 'Trend') //Foreign & Individual
            let series = [];
            let turnoverSeries = [];
            if (plotData['s50_ind']['mksum_s50']) {
                chart.applyOptions({
                    rightPriceScale: {
                        visible: true,
                    },
                    leftPriceScale: {
                        visible: true,
                    },
                });

                const seriesForeign = chart.addLineSeries({
                    // title: 'foreign',
                    priceScaleId: 'right',
                    color: 'blue'
                });
                // const seriesIndividual = chart.addLineSeries({
                //     title: 'individual',
                //     priceScaleId: 'right',
                //     color: 'red'
                // });

                // const seriesInstitution = chart.addLineSeries({ 
                //     title: 'institution',
                //     priceScaleId: 'left',
                //     color: 'purple'
                // });   

                const foreignData = generateData(plotData['s50_ind']['mksum_s50'][0], 'time', 'value');
                seriesForeign.setData(foreignData);

                // const individualData = generateData(plotData['s50_ind']['mksum_s50'][1], 'time', 'value');
                // seriesIndividual.setData(individualData);

                // const institutionData = generateData(plotData['s50_ind']['mksum_s50'][2], 'time', 'value');
                // seriesInstitution.setData(institutionData);

                const mksumSeries = [seriesForeign];

                mksumSeries.forEach(serie => {
                    serie.applyOptions({
                        priceFormat: {
                            type: 'volume',
                        }
                    });
                    series.push(serie);
                });

                const turnoverForeign = chart.addLineSeries({
                    title: 'eq_foreign',
                    priceScaleId: 'right',
                    color: 'blue'
                });
                // const turnoverIndividual = chart.addLineSeries({
                //     title: 'eq_individual',
                //     priceScaleId: 'right',
                //     color: 'red'
                // });

                // const turnoverInstitution = chart.addLineSeries({ 
                //     title: 'turnover_institution',
                //     priceScaleId: 'left',
                //     color: 'purple'
                // });  

                const turnoverForeignData = generateData(plotData['s50_ind']['mksum_s50'][3], 'time', 'value');
                turnoverForeign.setData(turnoverForeignData);

                // const turnoverIndividualData = generateData(plotData['s50_ind']['mksum_s50'][4], 'time', 'value');
                // turnoverIndividual.setData(turnoverIndividualData);

                // const turnoverInstitutionData = generateData(plotData['s50_ind']['mksum_s50'][5], 'time', 'value');
                // turnoverInstitution.setData(turnoverInstitutionData);

                const turnoverMksumSeries = [turnoverForeign];
                turnoverMksumSeries.forEach(serie => {
                    serie.applyOptions({
                        priceFormat: {
                            type: 'volume',
                        },
                        visible: false
                    });
                    turnoverSeries.push(serie);
                });

                const buttonContainer = document.createElement('div');
                buttonContainer.setAttribute('style', 'padding-left: 80%; position: absolute; z-index: 1;');

                const eq = hideSeriesButton(turnoverMksumSeries, 'Eq', '#FF4D02');
                const s50 = hideSeriesButton(mksumSeries, 'S50', '#FF4D02');

                buttonContainer.appendChild(eq);
                buttonContainer.appendChild(s50);

                filterContainer.appendChild(buttonContainer);
            } else {

            }

            return [chart, [...series, ...turnoverSeries], chartIndexContainer];
        }

        function generateIndexoutstanding() {
            const chartIndexContainer = document.createElement('div');
            chartIndexContainer.classList.add('chart-container');
            chartIndexContainer.setAttribute('id', `chart-4`)
            const chart = createChart(chartIndexContainer, chartOption);
            chart.applyOptions({
                crosshair: {
                    mode: CrosshairMode.Normal,
                    // Horizontal crosshair line (showing Price in Label)
                    horzLine: {
                        labelVisible: false
                    },
                },

            })
            chart.priceScale('right').applyOptions({
                visible: false,
                textColor: '#F5F5F5',

            });
            chart.priceScale('left').applyOptions({
                visible: false,
                textColor: '#F5F5F5',


            });

            chartAnnotation(chart, 'Mid Trend')

            const all_series = [];
            const series = chart.addLineSeries({ title: '', color: 'red', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'right', CrosshairMarkerVisible: false })
            const dataForSeries = generateData(plotData['s50_ind']['mid_trend'], 'date', 'close');
            series.setData(dataForSeries);
            series.applyOptions({
                priceFormat: {
                    type: 'volume',
                }
            })
            all_series.push(series);

            return [chart, all_series, chartIndexContainer]





        }



        function generateIndexMkb() {
            const chartIndexContainer = document.createElement('div');
            chartIndexContainer.classList.add('chart-container');
            chartIndexContainer.setAttribute('id', `chart-2`);
            const chart = createChart(chartIndexContainer, chartOption);
            chartAnnotation(chart, 'Market Breath');

            const series = chart.addLineSeries({
                title: 'score',
                color: 'blue',
                lastValueVisible: true,
                priceLineVisible: false,
                priceScaleId: 'right',
                CrosshairMarkerVisible: false
            });
            const dataForSeries = generateData(plotData['mkbt'], 'time', 'value');
            series.setData(dataForSeries);

            const mkb_value = plotData['mkbt'].map(item => item.value);
            const max_val = Math.max(...mkb_value);
            const min_val = Math.min(...mkb_value);
            // Create a new array with updated values
            const updatedDataMax = plotData['mkbt'].map(item => ({
                time: item.time,
                value: max_val
            }));
            const updatedDataMin = plotData['mkbt'].map(item => ({
                time: item.time,
                value: min_val
            }));

            const max_series = chart.addLineSeries({
                title: 'highest',
                color: 'blue',
                lineStyle: 1,
                lineWidth: 2,
                lastValueVisible: true,
                priceLineVisible: false,
                priceScaleId: 'right',
                CrosshairMarkerVisible: false
            });

            const min_series = chart.addLineSeries({
                title: 'lowest',
                color: 'blue',
                lineStyle: 1,
                lineWidth: 2,
                lastValueVisible: true,
                priceLineVisible: false,
                priceScaleId: 'right',
                CrosshairMarkerVisible: false
            });

            min_series.setData(generateData(updatedDataMin, 'time', 'value'));
            max_series.setData(generateData(updatedDataMax, 'time', 'value'));

            return [chart, [series], chartIndexContainer];
        }

        function generateIndexFlow() {
            const chartIndexContainer = document.createElement('div');
            chartIndexContainer.classList.add('chart-container');
            chartIndexContainer.setAttribute('id', `chart-3`)
            const chart = createChart(chartIndexContainer, chartOption);
            chart.applyOptions({
                crosshair: {
                    mode: CrosshairMode.Normal,
                    // Horizontal crosshair line (showing Price in Label)
                    horzLine: {
                        labelVisible: false
                    },
                },

            })
            chart.priceScale('right').applyOptions({
                visible: false,
                textColor: '#F5F5F5',

            });
            chart.priceScale('left').applyOptions({
                visible: false,
                textColor: '#F5F5F5',


            });

            chartAnnotation(chart, 'Confirm Up/Down')

            const all_series = [];
            const series = chart.addLineSeries({ title: '', color: 'black', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'right', CrosshairMarkerVisible: false })
            const dataForSeries = generateData(plotData['flow'], 'time', 'value');
            series.setData(dataForSeries);
            series.applyOptions({
                priceFormat: {
                    type: 'volume',
                }
            })
            all_series.push(series);

            return [chart, all_series, chartIndexContainer]


        }
        function generateIndexFlowS50() {
            const chartIndexContainer = document.createElement('div');
            chartIndexContainer.classList.add('chart-container');
            chartIndexContainer.setAttribute('id', `chart-3`)
            const chart = createChart(chartIndexContainer,
                {
                    width: chartWidth,
                    height: chartHeight,
                    crosshair: { mode: 1, },
                    timeScale: { visible: true },
                    layout: { background: { type: 'solid', color: '#f5f5f5' } },
                });
            chart.applyOptions({
                crosshair: {
                    mode: CrosshairMode.Normal,
                    // Horizontal crosshair line (showing Price in Label)
                    horzLine: {
                        labelVisible: false
                    },
                },

            })
            chart.priceScale('right').applyOptions({
                visible: false,
                textColor: '#F5F5F5',

            });
            chart.priceScale('left').applyOptions({
                visible: false,
                textColor: '#F5F5F5',


            });
            chartAnnotation(chart, 'Confirm Up/Down S50')

            const all_series = [];
            const series = chart.addLineSeries({ title: '', color: 'purple', lastValueVisible: false, priceScaleId: 'right', priceLineVisible: false, CrosshairMarkerVisible: false })
            const dataForSeries = generateData(plotData['s50_ind']['flow_s50'], 'time', 'value');
            series.setData(dataForSeries);
            series.applyOptions({
                priceFormat: {
                    type: 'volume',
                }
            })
            all_series.push(series);

            return [chart, all_series, chartIndexContainer]

        }

        function generateS50Scoring() {
            const chartIndexContainer = document.createElement('div');
            chartIndexContainer.classList.add('chart-container');
            chartIndexContainer.setAttribute('id', `chart-3`)
            const chart = createChart(chartIndexContainer,
                {
                    width: chartWidth,
                    height: chartHeight,
                    crosshair: { mode: 1, },
                    timeScale: { visible: true },
                    layout: { background: { type: 'solid', color: '#f5f5f5' } },
                });
            chart.applyOptions({
                crosshair: {
                    mode: CrosshairMode.Normal,
                    // Horizontal crosshair line (showing Price in Label)
                    horzLine: {
                        labelVisible: false
                    },
                },

            })
            chart.applyOptions({
                // rightPriceScale: {
                //     visible: true,
                // },
                leftPriceScale: {
                    visible: true,
                },
            });
            // chart.priceScale('right').applyOptions({
            //     visible: false,
            //     textColor: '#F5F5F5',

            // });
            // chart.priceScale('left').applyOptions({
            //     visible: false,
            //     textColor: '#F5F5F5',


            // });
            chartAnnotation(chart, 'S50 Scoring')

            const all_series = [];
            const series = chart.addLineSeries({ title: '', color: 'purple', lastValueVisible: false, priceScaleId: 'right', priceLineVisible: false, CrosshairMarkerVisible: false })
            // console.log('s50 scoring', plotData['s50_scoring'])
            const dataForSeries = generateData(plotData['s50_scoring'], 'time', 'value');
            series.setData(dataForSeries);
            series.applyOptions({
                priceFormat: {
                    type: 'volume',
                }
            })
            all_series.push(series);

            return [chart, all_series, chartIndexContainer]
        }

        //BidAsk
        function generateIndexBidask() {
            const chartIndexContainer = document.createElement('div');
            chartIndexContainer.classList.add('chart-container');

            const filterContainer = document.createElement('div');
            filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index: 3;');
            chartIndexContainer.appendChild(filterContainer);

            chartIndexContainer.setAttribute('id', `chart-8`);
            const chart = createChart(chartIndexContainer, chartOption);
            chartAnnotation(chart, 'ความน่าสนใจ');

            const bidaskOptions = { priceLineVisible: false, priceScaleId: 'right' };

            const bidAvgSeries = chart.addLineSeries({ color: 'red', lineStyle: 0, lineWidth: 3, title: '', ...bidaskOptions });
            const askAvgSeries = chart.addLineSeries({ color: 'green', lineStyle: 0, lineWidth: 3, title: '', ...bidaskOptions });
            const series5_3 = chart.addLineSeries({ color: '#013220', lineStyle: 1, lineWidth: 2, ...bidaskOptions });
            const series5_4 = chart.addLineSeries({ color: '#8B0000', lineStyle: 1, lineWidth: 2, ...bidaskOptions });



            const series = [bidAvgSeries, askAvgSeries, series5_3, series5_4];
            series.forEach((seri, index) => {
                // const dataForSeries = generateData(plotData['s50_ind']['ba_s50'][index], 'time', 'value');
                const dataForSeries = generateData(plotData['ba'][index], 'time', 'value');
                seri.setData(dataForSeries);
                seri.applyOptions({
                    priceFormat: {
                        type: 'volume',
                    }
                });
            });

            let s50Series = [];
            // if (plotData['s50_ind']['ba_s50']){
            //     series.forEach(seri => {
            //         seri.applyOptions({
            //             visible: false
            //          })
            //     })
            //     const s50BidaskOptions = {  priceLineVisible: false, priceScaleId: 'right' };

            //     const s50BidAvgSeries = chart.addLineSeries({ color: 'red', lineStyle: 0, lineWidth: 3, title: 'Bid Avg', ...s50BidaskOptions });
            //     const s50AskAvgSeries = chart.addLineSeries({ color: 'green', lineStyle: 0, lineWidth: 3, title: 'Ask Avg', ...s50BidaskOptions });
            //     const s50Series5_3 = chart.addLineSeries({ color: '#013220', lineStyle: 1, lineWidth: 2, ...s50BidaskOptions });
            //     const s50Series5_4 = chart.addLineSeries({ color: '#8B0000', lineStyle: 1, lineWidth: 2, ...s50BidaskOptions });
            //     const s50bidaskSeries = [s50BidAvgSeries, s50AskAvgSeries, s50Series5_3, s50Series5_4];

            //     s50bidaskSeries.forEach((seri, index) => {
            //         // const s50Data = generateData(plotData['ba'][index], 'time', 'value');
            //         const s50Data = generateData(plotData['s50_ind']['ba_s50'][index], 'time', 'value');
            //         seri.setData(s50Data);
            //         seri.applyOptions({
            //             priceFormat: {
            //                 type: 'volume',
            //             }
            //         });
            //         s50Series.push(seri);
            //     });

            //     const buttonContainer = document.createElement('div');
            //     buttonContainer.setAttribute('style', 'padding-left: 80%; position: absolute; z-index: 1;');

            //     const eq = hideSeriesButton(series, 'Eq','#FF4D02');
            //     const s50 = hideSeriesButton(s50Series, 'S50','#FF4D02');

            //     buttonContainer.appendChild(eq);
            //     buttonContainer.appendChild(s50);

            //     filterContainer.appendChild(buttonContainer);
            // } else{

            // } 

            return [chart, [...series, ...s50Series], chartIndexContainer]
        }


        function generateIndexTick() {
            const chartIndexContainer = document.createElement('div');
            chartIndexContainer.classList.add('chart-container');
            chartIndexContainer.setAttribute('id', `chart-5`)

            const filterContainer = document.createElement('div');
            filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index: 3;');
            chartIndexContainer.appendChild(filterContainer);


            const chart = createChart(chartIndexContainer, chartOption);
            chartAnnotation(chart, 'Tick Match')

            const tickOptions = { axisLabelVisible: true, lastValueVisible: false, priceLineVisible: false, priceScaleId: 'right' };
            const series6_1 = chart.addLineSeries({ color: 'red', lineStyle: 0, lineWidth: 3, title: '', ...tickOptions });
            const series6_2 = chart.addLineSeries({ color: 'green', lineStyle: 0, lineWidth: 3, title: '', ...tickOptions });
            const series6_3 = chart.addLineSeries({ color: '#013220', lineStyle: 1, lineWidth: 2, ...tickOptions });
            const series6_4 = chart.addLineSeries({ color: '#8B0000', lineStyle: 1, lineWidth: 2, ...tickOptions });

            const series = [series6_1, series6_2, series6_3, series6_4];

            series.forEach((series, index) => {
                const dataForSeries = generateData(plotData['tm'][index], 'time', 'value');
                series.setData(dataForSeries);

                series.applyOptions({
                    priceFormat: {
                        type: 'volume',
                    },
                });
            });
            let ticks50Series = [];
            if (plotData['s50_ind']['tm_s50']) {
                series.forEach(seri => {
                    seri.applyOptions({
                        visible: false
                    })
                })

                const s50_tickOptions = { axisLabelVisible: true, lastValueVisible: false, priceLineVisible: false, priceScaleId: 'left' };
                const tickSeries_1 = chart.addLineSeries({ color: 'red', lineStyle: 0, lineWidth: 3, title: '', ...s50_tickOptions });
                const tickSeries_2 = chart.addLineSeries({ color: 'green', lineStyle: 0, lineWidth: 3, title: '', ...s50_tickOptions });
                const tickSeries_3 = chart.addLineSeries({ color: '#013220', lineStyle: 1, lineWidth: 2, ...s50_tickOptions });
                const tickSeries_4 = chart.addLineSeries({ color: '#8B0000', lineStyle: 1, lineWidth: 2, ...s50_tickOptions });



                [tickSeries_1, tickSeries_2, tickSeries_3, tickSeries_4].forEach((seri, index) => {
                    const dataForSeries = generateData(plotData['s50_ind']['tm_s50'][index], 'time', 'value');
                    seri.setData(dataForSeries);

                    seri.applyOptions({
                        priceFormat: {
                            type: 'volume',
                        },
                    });
                    ticks50Series.push(seri)
                });

                const buttonContainer = document.createElement('div');
                buttonContainer.setAttribute('style', 'padding-left: 80%; position: absolute; z-index: 1;');

                const eq = hideSeriesButton(series, 'Eq', '#FF4D02');
                const s50 = hideSeriesButton(ticks50Series, 'S50', '#FF4D02');

                buttonContainer.appendChild(eq);
                buttonContainer.appendChild(s50);

                filterContainer.appendChild(buttonContainer);
            } else {

            }

            return [chart, [...series, ...ticks50Series], chartIndexContainer]
        }
        function hideSeriesButton(seriesList, text, color) {
            function createColorIndicator(color, isVisible) {
                const colorIndicator = document.createElement('span');
                colorIndicator.style.display = 'inline-block';
                colorIndicator.style.width = '10px';
                colorIndicator.style.height = '10px';
                colorIndicator.style.backgroundColor = isVisible ? color : 'transparent';
                colorIndicator.style.marginRight = '5px';
                colorIndicator.style.marginLeft = '5px';

                return colorIndicator;
            }

            const button = document.createElement('button');
            button.setAttribute('class', 'btn');
            button.textContent = text;
            button.setAttribute('style', 'z-index: 2;');
            button.appendChild(createColorIndicator(color, seriesList[0].options().visible));

            button.addEventListener('click', () => {
                // Toggle visibility for each series in the list
                const isVisible = !seriesList[0].options().visible;
                seriesList.forEach(series => {
                    series.applyOptions({
                        visible: isVisible,
                    });
                });
                // Update color indicator
                const colorIndicator = button.querySelector('span');
                colorIndicator.style.backgroundColor = isVisible ? color : 'transparent';
            });

            return button;
        }

        function generateIndexTransaction() {
            const chartIndexContainer = document.createElement('div');
            chartIndexContainer.classList.add('chart-container');

            const filterContainer = document.createElement('div');
            filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index: 3;');
            chartIndexContainer.appendChild(filterContainer);

            const chart = createChart(chartIndexContainer, chartOption);
            chartAnnotation(chart, 'กระแส')
            chartIndexContainer.setAttribute('id', `chart-6`)


            const transacOptions = { axisLabelVisible: true, lastValueVisible: false, priceLineVisible: false, priceScaleId: 'right' };
            const series7_1 = chart.addLineSeries({ color: 'green', lineStyle: 0, lineWidth: 3, title: '', ...transacOptions });
            const series7_2 = chart.addLineSeries({ color: 'red', lineStyle: 0, lineWidth: 3, title: '', ...transacOptions });
            const series7_3 = chart.addLineSeries({ color: '#8B0000', lineStyle: 1, lineWidth: 2, ...transacOptions });
            const series7_4 = chart.addLineSeries({ color: '#013220', lineStyle: 1, lineWidth: 2, ...transacOptions });

            const series = [series7_1, series7_2, series7_3, series7_4];

            series.forEach((series, index) => {
                const dataForSeries = generateData(plotData['transac'][index], 'time', 'value');
                series.setData(dataForSeries);

                series.applyOptions({
                    priceFormat: {
                        type: 'volume',
                    },
                });
            });
            let s50_series = [];
            if (plotData['s50_ind']['transac_s50']) {
                series.forEach(seri => {
                    seri.applyOptions({
                        visible: false
                    })
                })


                const s50_transacOptions = { axisLabelVisible: true, lastValueVisible: false, priceLineVisible: false, priceScaleId: 'left' };
                const s50_series7_1 = chart.addLineSeries({ color: 'green', lineStyle: 0, lineWidth: 3, title: '', ...s50_transacOptions });
                const s50_series7_2 = chart.addLineSeries({ color: 'red', lineStyle: 0, lineWidth: 3, title: '', ...s50_transacOptions });
                const s50_series7_3 = chart.addLineSeries({ color: '#8B0000', lineStyle: 1, lineWidth: 2, ...s50_transacOptions });
                const s50_series7_4 = chart.addLineSeries({ color: '#013220', lineStyle: 1, lineWidth: 2, ...s50_transacOptions });

                const transac_series = [s50_series7_1, s50_series7_2, s50_series7_3, s50_series7_4];

                transac_series.forEach((seri, index) => {
                    const dataForSeries = generateData(plotData['s50_ind']['transac_s50'][index], 'time', 'value');
                    seri.setData(dataForSeries);

                    seri.applyOptions({
                        priceFormat: {
                            type: 'volume',
                        },
                    });
                    s50_series.push(seri);
                });
                const buttonContainer = document.createElement('div');
                buttonContainer.setAttribute('style', 'padding-left: 80%; position: absolute; z-index: 1;');

                const eq = hideSeriesButton(series, 'Eq', '#FF4D02')
                const s50 = hideSeriesButton(s50_series, 'S50', '#FF4D02')

                buttonContainer.appendChild(eq);
                buttonContainer.appendChild(s50);

                filterContainer.appendChild(buttonContainer);
            } else {
            }
            return [chart, [...series, ...s50_series], chartIndexContainer]
        }
        // function generateIndexSwap() {
        //     const chartIndexContainer = document.createElement('div');
        //     chartIndexContainer.classList.add('chart-container');
        //     chartIndexContainer.setAttribute('id',`chart-7`);

        //     const filterContainer = document.createElement('div');
        //     filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index: 3;');
        //     chartIndexContainer.appendChild(filterContainer);

        //     const chart = LightweightCharts.createChart(chartIndexContainer, chartOption);
        //     chart.applyOptions({
        //         crosshair: {
        //             mode: LightweightCharts.CrosshairMode.Normal,
        //             // Horizontal crosshair line (showing Price in Label)
        //             horzLine: {
        //                 labelVisible:false
        //             },
        //         },

        //     })
        //     chart.priceScale('right').applyOptions({
        //         visible:false,
        //         textColor: '#F5F5F5',

        //         });
        //     chart.priceScale('left').applyOptions({
        //             visible:false,
        //             textColor: '#F5F5F5',


        //             });
        //     chartAnnotation(chart, 'Peak');


        //     const allsig_series = chart.addLineSeries({color :'purple',lineStyle: 0,lineWidth: 2,title: 's50_sum',lastValueVisible: false,priceLineVisible: false,priceScaleId: 'right'});

        //     const dataForSeries = generateData(plotData['swap'], 'time', 'value');
        //     allsig_series.setData(dataForSeries);

        //     // let s50_series;
        //     // if (plotData['s50_ind']['swap_s50']){
        //     //     allsig_series.applyOptions({
        //     //         visible: false,
        //     //      })

        //     //     const s50_series = chart.addLineSeries({color :'black',lineStyle: 0,lineWidth: 2,title: 's50_sum',lastValueVisible: false,priceLineVisible: false,priceScaleId: 'right'});
        //     //     const dataForSeries = generateData(plotData['s50_ind']['swap_s50'], 'time', 'value');
        //     //     s50_series.setData(dataForSeries);

        //     //     const buttonContainer = document.createElement('div');
        //     //     buttonContainer.setAttribute('style', 'padding-left: 80%; position: absolute; z-index: 1;');

        //     //     const eq = hideSeriesButton([allsig_series], 'Eq','#FF4D02')
        //     //     const s50 = hideSeriesButton([s50_series], 'S50','#FF4D02')

        //     //     buttonContainer.appendChild(eq);
        //     //     buttonContainer.appendChild(s50);

        //     //     filterContainer.appendChild(buttonContainer);
        //     // }
        //     return [chart,[ allsig_series ], chartIndexContainer]
        // }

        function generateIndexPeak() {
            const chartIndexContainer = document.createElement('div');
            chartIndexContainer.classList.add('chart-container');
            chartIndexContainer.setAttribute('id', `chart-7`)

            const filterContainer = document.createElement('div');
            filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index: 3;');
            chartIndexContainer.appendChild(filterContainer);

            const chart = createChart(chartIndexContainer, chartOption);
            chartAnnotation(chart, 'Peak');

            const series_1 = chart.addLineSeries({ color: 'red', lineStyle: 0, lineWidth: 2, title: 'eq_bid', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'right' });
            const series_2 = chart.addLineSeries({ color: 'green', lineStyle: 0, lineWidth: 2, title: 'eq_ask', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'right' })
            const series_3 = chart.addLineSeries({ color: 'black', lineStyle: 0, lineWidth: 2, title: 'eq_sum', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'right' })

            const series = [series_1, series_2, series_3];
            series.forEach((seri, index) => {
                const dataForSeries = generateData(plotData['swap'][index], 'time', 'value');
                seri.setData(dataForSeries);

            })
            let s50_series = [];
            if (plotData['s50_ind']['swap_s50']) {
                series.forEach(seri => {
                    seri.applyOptions({
                        visible: false
                    })
                })

                const s50_series_1 = chart.addLineSeries({ color: 'red', lineStyle: 0, lineWidth: 2, title: 's50_bid', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'left' });
                const s50_series_2 = chart.addLineSeries({ color: 'green', lineStyle: 0, lineWidth: 2, title: 's50_ask', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'left' });
                const s50_series_3 = chart.addLineSeries({ color: 'black', lineStyle: 0, lineWidth: 2, title: 's50_sum', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'left' });

                const swap_series = [s50_series_1, s50_series_2, s50_series_3];
                swap_series.forEach((seri, index) => {
                    const dataForSeries = generateData(plotData['s50_ind']['swap_s50'][index], 'time', 'value');
                    seri.setData(dataForSeries);
                    s50_series.push(seri);
                });
                const buttonContainer = document.createElement('div');
                buttonContainer.setAttribute('style', 'padding-left: 80%; position: absolute; z-index: 1;');

                const eq = hideSeriesButton(series, 'Eq', '#FF4D02')
                const s50 = hideSeriesButton(s50_series, 'S50', '#FF4D02')

                buttonContainer.appendChild(eq);
                buttonContainer.appendChild(s50);

                filterContainer.appendChild(buttonContainer);
            } else {

            }
            return [chart, [...series, ...s50_series], chartIndexContainer];
        }
        // function generateIndexChart8(){
        //     const chartIndexContainer = document.createElement('div');
        //     chartIndexContainer.classList.add('chart-container'); 
        //     chartIndexContainer.setAttribute('id',`chart-8`)
        //     const chart = LightweightCharts.createChart(chartIndexContainer, chartOption);
        //     chartAnnotation(chart, '%short');   
        //     const dataForSeries = generateData(plotData[8], 'time', 'value');

        //     const series = chart.addLineSeries({color:'red'});
        //     series.setData(dataForSeries)

        //     return [chart, [series], chartIndexContainer];

        // }
        const [chart0, series0, chartIndexContainer0] = generateIndexLast();
        const [chart1, series1, chartIndexContainer1] = generateIndexFlow();
        const [chart4, series4, chartIndexContainer4] = generateIndexoutstanding();
        const [chart5, series5, chartIndexContainer5] = generateIndexTick();
        const [chart6, series6, chartIndexContainer6] = generateIndexTransaction();
        const [chart7, series7, chartIndexContainer7] = generateIndexMkb();
        const [chart8, series8, chartIndexContainer8] = generateIndexoutstanding();

        console.log('result1:', { chart0, series0, chartIndexContainer0 });
        console.log('result2:', { chart1, series1, chartIndexContainer1 });
        console.log('result3:', { chart4, series4, chartIndexContainer4 });
        console.log('result4:', { chart5, series5, chartIndexContainer5 });
        console.log('result5:', { chart6, series6, chartIndexContainer6 });
        console.log('result6:', { chart7, series7, chartIndexContainer7 });
        console.log('result7:', { chart8, series8, chartIndexContainer8 });

        let allSeries, charts, allIndexChartContainers, allIndexGenerators;

        if (symbol === 'SET50') {
            const [chart2, series2, chartIndexContainer2] = generateIndexFlowS50();
            const [chart3, series3, chartIndexContainer3] = generateIndexChartS50Last();
            const [chart7, series7, chartIndexContainer7] = generateIndexBidask();
            const [chart8, series8, charIndexContainer8] = generateS50Scoring();

            console.log('result9:', { chart2, series2, chartIndexContainer2 });
            console.log('result10:', { chart3, series3, chartIndexContainer3 });
            console.log('result11:', { chart7, series7, chartIndexContainer7 });
            console.log('result12:', { chart8, series8, chartIndexContainer8 });

            // allSeries = [series0, series1, series2, series3, series4, series5, series8, series6, series7];
            // charts = [chart0, chart1, chart2, chart3, chart4, chart5, chart8, chart6, chart7];
            // allIndexChartContainers = [chartIndexContainer0, chartIndexContainer1, chartIndexContainer2, chartIndexContainer3, chartIndexContainer4, chartIndexContainer4, charIndexContainer8, chartIndexContainer5, chartIndexContainer6, chartIndexContainer7];
            // allIndexGenerators = [generateIndexLast, generateIndexFlow, generateIndexFlowS50, generateIndexChartS50Last, generateIndexBidask, generateIndexTick, generateS50Scoring, generateIndexTransaction, generateIndexoutstanding];

            // allSeries = [series0, series2, series3, series4, series8];
            // charts = [chart0, chart2, chart3, chart4, chart8];
            // allIndexChartContainers = [chartIndexContainer0, chartIndexContainer2, chartIndexContainer3, chartIndexContainer4, chartIndexContainer4, charIndexContainer8];
            // allIndexGenerators = [generateIndexLast, generateIndexFlowS50, generateIndexChartS50Last, generateIndexBidask, generateIndexTick];

            // allSeries = [series0, series1, series2, series3, series8];
            // charts = [chart0, chart1, chart2, chart3, chart8];
            // allIndexChartContainers = [chartIndexContainer0, chartIndexContainer1, 
            //     chartIndexContainer2, chartIndexContainer3, charIndexContainer8];
            // allIndexGenerators = [generateIndexLast, generateIndexFlow, 
            //     generateIndexFlowS50, generateIndexChartS50Last, charIndexContainer8];

            allSeries = [series0, series2, series3, series4, series8];
            charts = [chart0, chart2, chart3, chart4, chart8];
            allIndexChartContainers = [chartIndexContainer0, chartIndexContainer2, chartIndexContainer3, chartIndexContainer4, charIndexContainer8];
            allIndexGenerators = [generateIndexLast, generateIndexFlowS50, generateIndexChartS50Last, generateIndexoutstanding, generateS50Scoring];

            // allSeries = [series0, series1, series2, series3, chart8, series4, series5, series6];
            // charts = [chart0, chart1, chart2, chart3, chart8, chart4, chart5, chart6, chart7];
            // allIndexChartContainers = [chartIndexContainer0, chartIndexContainer1, chartIndexContainer2, chartIndexContainer3, charIndexContainer8, 
            //     chartIndexContainer4, chartIndexContainer4, chartIndexContainer5, chartIndexContainer6];
            // allIndexGenerators = [generateIndexLast, generateIndexFlow, generateIndexFlowS50, generateIndexChartS50Last, generateS50Scoring,
            //      generateIndexBidask, generateIndexTick, generateIndexTransaction];        
        } else {

            allSeries = [series0, series1, series4, series5, series6];
            charts = [chart0, chart1, chart4, chart5, chart6];
            allIndexChartContainers = [chartIndexContainer0, chartIndexContainer1, chartIndexContainer5, chartIndexContainer6, chartIndexContainer8];
            allIndexGenerators = [generateIndexLast, generateIndexFlow, generateIndexBidask, generateIndexTick, generateIndexTransaction, generateIndexoutstanding];
        }
        var peak = false;
        if (peak) {
            const [chart_peak, series_peak, chartContainerPeak] = generateIndexPeak();
            allChartContainers.push(chartContainerPeak);
            allSeries.push(series_peak);
            chart_peak.push(series_peak);
        }


        charts.forEach((currentChart, currentIndex) => {
            currentChart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
                charts.forEach((otherChart, otherIndex) => {
                    if (currentIndex !== otherIndex) {
                        otherChart.timeScale().setVisibleLogicalRange(timeRange);
                    }
                });
            });
        });
        charts.forEach((currentChart, currentIndex) => {
            currentChart.subscribeCrosshairMove(param => {
                const dataPoint = getCrosshairDataPoint(allSeries[currentIndex][0], param);

                charts.forEach((otherChart, otherIndex) => {
                    if (currentIndex !== otherIndex) {
                        allSeries[otherIndex].forEach(otherSeries => {
                            syncCrosshair(otherChart, otherSeries, dataPoint);
                        });
                    }
                });
            });
        });
        function renderChartInNewWindow(chartIndexContainer, generateIndexChart) {
            // Specify window dimensions and position
            const windowWidth = 800;
            const windowHeight = 600;
            // const windowWidth = 900;
            // const windowHeight = 700;
            const windowLeft = window.screen.width / 2 - windowWidth / 2;
            const windowTop = window.screen.height / 2 - windowHeight / 2;

            // Open a new browser window
            const newWindow = window.open('', 'Chart Window', `width=${windowWidth},height=${windowHeight},left=${windowLeft},top=${windowTop}`);

            // Write the basic HTML structure
            newWindow.document.write('<html><head><title>Chart Popup</title></head><body></body></html>');

            // Create a div container
            const container = newWindow.document.createElement('div');
            container.classList.add('grid', 'grid-cols-[1fr,3fr]', 'grid-rows-1', 'gap-2');
            newWindow.document.body.appendChild(container);

            // Add Tailwind CSS
            const tailwindLink = newWindow.document.createElement('link');
            tailwindLink.href = 'https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css';
            tailwindLink.rel = 'stylesheet';
            newWindow.document.head.appendChild(tailwindLink);

            // Append the chart container to the new window
            const nameContainer = newWindow.document.createElement('div');
            const symbolName = newWindow.document.createElement('h2');
            symbolName.textContent = symbol; // Ensure 'symbol' is defined
            nameContainer.appendChild(symbolName);
            container.appendChild(nameContainer);
            container.appendChild(chartIndexContainer);
            ;

            // Ensure that the chart and series are interactive after scripts are loaded
            newWindow.addEventListener('DOMContentLoaded', () => {
                // Reinitialize or modify the chart/series in the new window
                const [newChart, newSeries, newChartContainer] = generateIndexChart();
                container.innerHTML = ''; // Clear previous content
                container.appendChild(newChartContainer);
            });
        }
        function addRenderButton(generateIndexChart, chartIndexContainer) {
            const [chart, series, container] = generateIndexChart();
            document.querySelectorAll('.newtabButton').forEach(button => {
                button.removeEventListener('click', () => renderChartInNewWindow(container, generateIndexChart));
            });
            const renderButton = document.createElement('button');
            renderButton.setAttribute('class', 'btn');
            renderButton.setAttribute('style', 'z-index:50; position:absolute;');

            renderButton.classList.add("newtabButton");
            renderButton.textContent = '🔎';
            // renderButton.addEventListener('click', () => renderChartInNewWindow(container, generateIndexChart));

            // const renderButtonContainer = document.createElement('div');
            // renderButtonContainer.setAttribute('style','z-index:999; position:relative;')
            // renderButtonContainer.appendChild(renderButton);
            // chartIndexContainer.insertBefore(renderButtonContainer, chartIndexContainer.firstChild);

            renderButton.addEventListener('click', clickHandler);

            const renderButtonContainer = document.createElement('div');
            renderButtonContainer.setAttribute('style', 'z-index:999; position:relative;')
            renderButtonContainer.appendChild(renderButton);
            chartIndexContainer.insertBefore(renderButtonContainer, chartIndexContainer.firstChild);

            function clickHandler() {
                renderChartInNewWindow(container, generateIndexChart, symbol);
            }
        }



        allIndexChartContainers.forEach((container, index) => {
            // addRenderButton(allIndexGenerators[index], container);
            responseContainer.appendChild(container);
        });

        chatbox.appendChild(responseContainer);
    }
    ////////////////////////////////////////////////////
    function initializeDataTable(container) {
        const dataTable = container.querySelector('table');
        if (dataTable) {
            // Initialize DataTable if not already initialized
            if (!$(dataTable).DataTable()) {
                $(dataTable).DataTable({
                    "index": false  // Set the index option to false
                });
            }
        }
    }

    function generateData(data, date_columm = 'date', field = 'value') {
        const timeZoneOffset = 7 * 60 * 60 * 1000;
        const cdata = data.map(d => {
            let time = timeToTz(d[date_columm], selectedTimezone)
            let time2 = (new Date(d[date_columm]).getTime() + timeZoneOffset) / 1000

            return { time: time2, value: parseFloat(d[field]) || 0 }
        });
        return cdata;
    }
    // function generateData(data, date_column = 'date', field = 'value') {
    //     if (!Array.isArray(data)) {
    //         console.log('Data is not an array or is undefined');
    //         return null;
    //     }

    //     const timeZoneOffset = 7 * 60 * 60 * 1000;
    //     console.log(data);

    //     const cdata = data.map(d => {
    //         if (!d || typeof d !== 'object') {
    //             console.log('Invalid data item:', d);
    //             return null;
    //         }

    //         let time = timeToTz(d[date_column], selectedTimezone);
    //         let time2 = (new Date(d[date_column]).getTime() + timeZoneOffset) / 1000;

    //         return { time: time2, value: parseFloat(d[field]) || 0 };
    //     }).filter(item => item !== null);

    //     return cdata.length > 0 ? cdata : null;
    // }
    function timeToTz(originalTime, timeZone) {
        const zonedDate = new Date(new Date(originalTime).toLocaleString('en-US', { timeZone }));
        const options = {
            timeZone: timeZone,
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
        };
        const formatter = new Intl.DateTimeFormat('en-US', options);
        return zonedDate.getTime() / 1000;
    }
    function getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    function generateUniqueRandomColors(length) {
        const uniqueColors = new Set();

        while (uniqueColors.size < length) {
            const color = getRandomColor();
            uniqueColors.add(color);
        }

        return Array.from(uniqueColors);
    }
    function syncCrosshair(chart, series, dataPoint) {
        if (dataPoint) {
            chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
            return;
        }
        chart.clearCrosshairPosition();
    }
    function getCrosshairDataPoint(series, param) {
        if (!param.time) {
            return null;
        }
        const dataPoint = param.seriesData.get(series);
        return dataPoint || null;
    }

    const func1 = () => {
        // Function to be executed when PlayIcon is clicked
        console.log("Function 1 executed");
    };
    const func2 = () => {
        // Function to be executed when StopIcon is clicked
        console.log("Function 2 executed");
        document.getElementById('chatbox').innerHTML = '';
        setIsMessageSent(false);
    };

    return (
        <>
            <style>
                {`
      .toggleButton {
        position: relative; /* Make sure it's the desired position      transform: translate(-50%, -500%);*/
        top: -90%;
        right: -45%;
        width: 30%;
        z-index: 90000000 !important;
    }

    .header-text {
        position: relative; /* Make sure it's the desired position      transform: translate(-50%, -500%);*/
        top: -93%;
        right: -16%;
        width: 30%;
        z-index: 9000000000 !important;
    }

    .divHeader {
        position: relative; /* Make sure it's the desired position      transform: translate(-50%, -500%);*/
        top: -90%;
        left: 16%;
        width: 60%;
        display: flex;
        justify-content: space-between;
        z-index: 90000000 !important;
    }

    .toggleButton2 {
        position: relative; /* Make sure it's the desired position      transform: translate(-50%, -500%);*/
        top: -90%;
        right: -45%;
        width: 30%;
        z-index: 90000000 !important;
    }

    .toggleButton246 {
        position: relative; /* Make sure it's the desired position      transform: translate(-50%, -500%);*/
        top: -90%;
        right: -52%;
        width: 28%;
        z-index: 90000000 !important;
    }
    
          
      
    `}
            </style>
            <Container maxWidth="xl" style={{
                marginTop: 0, width: '100%', height: 'calc(100vh - var(--top-bar-height))', display: 'flex', flexDirection: 'column', padding: 5,



            }}>
                <Paper elevation={3} style={{ padding: 10, flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div id="resChatBox"
                        ref={resChatBoxRef}
                        style={{
                            // position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            overflowY: 'auto',
                            // backgroundColor: 'red'
                        }}>

                        <div id="chatbox" style={{
                            width: '100%',
                            height: '100%',
                        }}>
                            {messages.map((message, index) => (
                                <div key={index} style={{ textAlign: message.isBot ? 'left' : 'right' }}>
                                    <Typography variant="body1" style={{ marginBottom: 5 }}>
                                        {message.text}
                                    </Typography>
                                </div>
                            ))}
                            {isMessageSent && (
                                <LoadingIndicator />
                            )}
                        </div>

                    </div>

                    <div style={{ display: 'flex', marginTop: 8 }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Type a message..."
                            value={inputValue}
                            onChange={handleInputChange}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') handleSendMessage();
                            }}
                            size="small"
                            sx={{
                                border: '1px solid',
                                borderColor: '#00000', // You can change the border color as needed
                                borderRadius: '5px'  // Adjust the border radius if required
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton sx={{ color: 'blue' }} onClick={handleSendMessage}>
                                            <PlayCircleIcon />
                                        </IconButton>
                                        <IconButton onClick={func2}>
                                            <RestartAltIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            disabled={isMessageSent}
                        />

                    </div>
                </Paper>
            </Container>
        </>
    );
};

export default ChatbotPage;
